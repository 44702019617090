import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../../store/login/loginSlice';
import { isMobile } from 'react-device-detect';
import {
  Stack,
  Typography,
  Card,
  Button,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Box
} from '@mui/material';

import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import configAxios from '../../../../services/configAxios';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';

const addEditProducts = (props) => {
  const navigation = useNavigate();
  const location = useLocation();
  const { state, pathname } = location;
  const pathNameSplice = pathname.split('/');
  const [infosProduct, setInfosProduct] = useState(state ? { ...state.infosEdit } : {});

  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  useEffect(() => {
    if (state === null && pathNameSplice[2] === 'editProducts') [navigation('/app/products')];
  }, []);

  function HandleInputChange(event) {
    setInfosProduct({ ...infosProduct, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    const router = infosProduct.id ? '/products/updateProduct' : '/products/addProduct';
    configAxios
      .post(router, infosProduct)
      .then((response) => {
        const message = infosProduct.id
          ? 'Produto atualizado com sucesso!'
          : 'Produto inserido com sucesso!';

        NotificationManager.success(message, 'Sucesso');

        setInfosProduct({
          ...infosProduct,
          id: response.data.id,
          codplano: response.data.codplano
        });

        const nav = infosProduct.id
          ? `/app/editProducts/${infosProduct.id}`
          : `/app/editProducts/${response.data.id}`;

        navigation(nav, {
          state: {
            infosEdit: {
              ...infosProduct,
              id: infosProduct.id ? infosProduct.id : response.data.id
            }
          }
        });
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }
  return (
    <RenderPage title="Produtos" id="products">
      <>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de produtos
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para inserir e editar os produtos
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/products"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <form onSubmit={HandleSubmit}>
              <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 2 }}>
                <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {infosProduct.id && (
                    <Grid item xs={3} md={1}>
                      <TextField
                        name="idproduct"
                        fullWidth
                        label="ID"
                        variant="outlined"
                        size="small"
                        required
                        value={infosProduct.id || ''}
                        disabled
                      />
                    </Grid>
                  )}
                  {infosProduct.codplano && (
                    <Grid item xs={6} md={2}>
                      <TextField
                        name="codplano"
                        fullWidth
                        label="Código do produto/plano"
                        variant="outlined"
                        size="small"
                        required
                        value={infosProduct.codplano || ''}
                        disabled
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      name="descplano"
                      fullWidth
                      label="Descrição do plano"
                      variant="outlined"
                      size="small"
                      required
                      value={infosProduct.descplano || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 200 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { mt: 0 } : { fontSize: '13px', mt: -2.2 }}>
                      Tipo de contratação *
                    </InputLabel>
                    <Select
                      name="tpcontrat"
                      fullWidth
                      size="small"
                      required
                      value={infosProduct.tpcontrat || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={'1-INDIVIDUAL/FAMILIAR'}>1-INDIVIDUAL/FAMILIAR</MenuItem>
                      <MenuItem value={'2-COLETIVO EMPRESARIAL'}>2-COLETIVO EMPRESARIAL</MenuItem>
                      <MenuItem value={'3-COLETIVO POR ADESAO'}>3-COLETIVO POR ADESÃO</MenuItem>
                    </Select>
                  </Grid>
                  {infoState.tipooperacao !== 'Cartão' && (
                    <Grid item xs={12} md={2}>
                      <TextField
                        name="regplano"
                        fullWidth
                        label="Registro ANS"
                        variant="outlined"
                        size="small"
                        required
                        value={infosProduct.regplano || ''}
                        onChange={(event) => HandleInputChange(event)}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                  )}
                  {infoState.modalidadecontrato === 'Contrato por plano' &&
                    infosProduct.tpcontrat !== '3-COLETIVO POR ADESAO' &&
                    infoState.tipo === 'Complexo' && (
                      <Grid item xs={12} md={2}>
                        <TextField
                          name="codcontrato"
                          fullWidth
                          label="Código do contrato"
                          variant="outlined"
                          size="small"
                          required
                          value={infosProduct.codcontrato || ''}
                          onChange={(event) => HandleInputChange(event)}
                          inputProps={{ maxLength: 10 }}
                        />
                      </Grid>
                    )}
                  {infoState.tipointegracao !== 'Não possui' && (
                    <Grid item xs={12} md={2}>
                      <TextField
                        name="codext"
                        fullWidth
                        label="Código externo"
                        variant="outlined"
                        size="small"
                        required
                        value={infosProduct.codext || ''}
                        onChange={(event) => HandleInputChange(event)}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                  )}
                  {infoState.tipointegracao !== 'Não possui' && infoState.tipo === 'Complexo' && (
                    <Grid item xs={12} md={2}>
                      <InputLabel
                        id="label"
                        sx={
                          infosProduct.tpcontrat !== '3-COLETIVO POR ADESAO' &&
                          infoState.tipooperacao !== 'Cartão'
                            ? { mt: 0, fontSize: '13px' }
                            : { mt: -2.2, fontSize: '13px' }
                        }>
                        Status *
                      </InputLabel>
                      <Select
                        name="status"
                        fullWidth
                        size="small"
                        required
                        value={infosProduct.status || ''}
                        onChange={(event) => HandleInputChange(event)}>
                        <MenuItem value={'ATIVO'}>Ativo</MenuItem>
                        <MenuItem value={'INATIVO'}>Inativo</MenuItem>
                      </Select>
                    </Grid>
                  )}
                  {(infoState.tipointegracao === 'Não possui' || infoState.tipo !== 'Complexo') && (
                    <Grid item xs={12} md={2}>
                      <InputLabel
                        id="label"
                        sx={isMobile ? { mt: 0 } : { fontSize: '13px', mt: -2.2 }}>
                        Status *
                      </InputLabel>
                      <Select
                        name="status"
                        fullWidth
                        size="small"
                        required
                        value={infosProduct.status || ''}
                        onChange={(event) => HandleInputChange(event)}>
                        <MenuItem value={'ATIVO'}>Ativo</MenuItem>
                        <MenuItem value={'INATIVO'}>Inativo</MenuItem>
                      </Select>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="success"
                  sx={{ mr: 2 }}
                  startIcon={<Iconify icon="eva:save-fill" />}>
                  {infosProduct.id ? 'Atualizar' : 'Salvar'}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to="/app/products"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </Stack>
            </form>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default addEditProducts;
