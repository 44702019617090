import React, { useState, useEffect } from 'react';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../../store/login/loginSlice';

import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Tab
} from '@mui/material';

import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing,
  Selection
} from 'devextreme-react/data-grid';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import configAxios from '../../../../services/configAxios';
import Iconify from '../../../../@core/theme/Iconify';

const allowedPageSizes = [5, 10, 20, 30];
let rowsSelected = [];
/**
 * id_type_client === 1 - Físico
 * id_type_client === 2 - Júridico
 *
 */
const sendEmailContract = (props) => {
  const [infosClients] = useState(props.infosClients);
  const [infosHistoryEmail, setInfosHistoryEmail] = useState();
  const [emailsHolders, setEmailsHoders] = useState();
  const [value, setValue] = useState('1');
  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  useEffect(() => {
    filterHolders(props.infosDeps, props.infosClients);
    infosHistoryEmailSet(props.infosHistoryEmail);
  }, [props.infosDeps, props.infosHistoryEmail]);

  async function filterHolders(infosDeps, infosClientsHolder) {
    const emails = [];
    if (infosClients.id_type_client === 2) {
      if (infosClients.included_resp === 'N' || infoState.tipooperacao === 'Odonto') {
        emails.push({
          id: infosClientsHolder.id,
          nome: infosClientsHolder.nome,
          email: infosClientsHolder.email,
          cpf: infosClientsHolder.cpf_cnpj_resp
        });
      }
      if (infosDeps.length > 0 && infoState.tipooperacao !== 'Odonto') {
        infosDeps.map((element) => {
          if (element.tpbenef === 'Titular') {
            emails.push({
              id: element.id,
              nome: element.nome,
              email: element.email,
              cpf: element.cpf_cnpj
            });
          }
        });
      }
    }
    setEmailsHoders(emails);
  }

  function infosHistoryEmailSet(infosEmail) {
    if (infosEmail.length > 0) {
      setInfosHistoryEmail(infosEmail);
    }
  }

  function HandleTabsChange(event, newValue) {
    setValue(newValue);
  }

  function selectedRows(event) {
    rowsSelected = event.selectedRowsData;
  }

  function EnvioDate(date) {
    return date.data.data.dataenvio.replaceAll('-', ':');
  }

  function HandleSubmit(event) {
    event.preventDefault();

    if (infosClients.id_type_client === 2 && !rowsSelected.length) {
      NotificationManager.warning('Por favor, selecione os emails para enviar!', 'Atenção');
      return;
    }

    if (!props.infosClients.senha) {
      NotificationManager.warning('Por favor, gere a senha para enviar!', 'Atenção');
      return;
    }

    const emailNotification = {
      infosClients: infosClients,
      infoState: props.infoState,
      infosDeps: props.infosDeps,
      clientName: props.infosClient,
      emailsHolders: rowsSelected
    };

    configAxios
      .post('/sendEmail/sendEmailDocuments', emailNotification)
      .then(() => {
        NotificationManager.success('Emails enviados com sucesso!', 'Sucesso!');
        props.setOpenModal(false);
        props.functionGetHistory();
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para enviar os emails, tente novamente!',
          'Atenção'
        );
      });
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'sm'}
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
            }}>
            Enviar e-mails para assinaturas
          </BootstrapDialogTitle>
          <form onSubmit={HandleSubmit}>
            <DialogContent dividers>
              <TabContext value={value} sx={{ padding: 0 }}>
                <TabList onChange={HandleTabsChange} aria-label="SendEmailContract">
                  <Tab label="Histórico" value="1" />
                  {infosClients.id_type_client === 2 && (
                    <Tab label="E-mails" value="2" sx={{ textTransform: 'none' }} />
                  )}
                </TabList>
                <TabPanel value="1" variant="fullWidth">
                  {!isMobile && (
                    <>
                      {/* <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                          Historico de envios
                        </Typography>
                      </Grid> */}
                      <Grid item xs={12} sx={{ mt: -1 }}>
                        <Typography variant="subtitle2" gutterBottom>
                          Acompanhe abaixo o histórico de envios
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <DataGrid
                    dataSource={infosHistoryEmail}
                    showBorders={true}
                    remoteOperations={true}
                    wordWrapEnabled={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    noDataText={'Não foram encontrados registros de envio'}>
                    <Column dataField="nome" caption="Nome" />
                    <Column
                      dataField="send_at"
                      caption="Data do envio"
                      cellRender={(data) => <EnvioDate data={data} />}
                    />
                    <SearchPanel visible={true} width={220} placeholder="Procurar..." />
                    <GroupPanel
                      visible={true}
                      emptyPanelText="Arraste as colunas aqui para agrupar"
                    />
                    <Scrolling rowRenderingMode="virtual"></Scrolling>
                    <Paging defaultPageSize={5} />
                    <Pager
                      visible={true}
                      allowedPageSizes={allowedPageSizes}
                      showPageSizeSelector={true}
                      showInfo={true}
                      showNavigationButtons={true}
                    />
                    <ColumnChooser
                      enabled={true}
                      title={'Escolher uma coluna'}
                      emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                    />
                    <ColumnFixing enabled={true} />
                    <HeaderFilter visible={true} allowSearch={true} />
                  </DataGrid>
                </TabPanel>
                <TabPanel value="2">
                  {!isMobile && (
                    <>
                      {/* <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                          Selecionar e-mails
                        </Typography>
                      </Grid> */}
                      <Grid item xs={12} sx={{ mt: -1 }}>
                        <Typography variant="subtitle2" gutterBottom>
                          Selecione abaixo os e-mails para enviar
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <DataGrid
                    dataSource={emailsHolders}
                    showBorders={true}
                    remoteOperations={true}
                    wordWrapEnabled={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    onSelectionChanged={selectedRows}
                    noDataText={'Não foram encontrados e-mails para enviar'}>
                    <Selection mode="multiple" />
                    <Column dataField="nome" caption="Nome" />
                    <Column dataField="email" caption="E-mail" />
                    {/* <Column dataField="cpf" caption="Cpf" /> */}
                    <SearchPanel visible={true} width={220} placeholder="Procurar..." />
                    <GroupPanel
                      visible={true}
                      emptyPanelText="Arraste as colunas aqui para agrupar"
                    />
                    <Scrolling rowRenderingMode="virtual"></Scrolling>
                    <Paging defaultPageSize={10} />
                    <Pager
                      visible={true}
                      allowedPageSizes={allowedPageSizes}
                      showPageSizeSelector={true}
                      showInfo={true}
                      showNavigationButtons={true}
                    />
                    <ColumnChooser
                      enabled={true}
                      title={'Escolher uma coluna'}
                      emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                    />
                    <ColumnFixing enabled={true} />
                    <HeaderFilter visible={true} allowSearch={true} />
                  </DataGrid>
                </TabPanel>
              </TabContext>
            </DialogContent>
            <DialogActions>
              <Button
                color="success"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:email-outline" />}>
                Enviar
              </Button>
              <Button
                onClick={() => {
                  props.setOpenModal(false);
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Cancelar
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default sendEmailContract;
