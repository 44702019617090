import React, { useState } from 'react';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';
import { isMobile } from 'react-device-detect';
import { NotificationManager } from 'react-notifications';
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField
  // Typography
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import configAxios from '../../../../services/configAxios';
import Iconify from '../../../../@core/theme/Iconify';

const sendEmailNotification = (props) => {
  const [infosSimulations] = useState(props.infosSimulations);
  const [sendEmailCopy, setSendEmailCopy] = useState('');

  function HandleInputChange(event) {
    setSendEmailCopy({ ...sendEmailCopy, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();

    let valuesSimulations = 0;
    props.values.map((element) => {
      valuesSimulations += +element.valsimulation;
    });

    const emailNotification = {
      sendEmail: infosSimulations.email,
      sendEmailCopy: sendEmailCopy.sendEmailCopy,
      infosSimulations: infosSimulations,
      values: props.values,
      valueTotal: Number(valuesSimulations).toFixed(2)
    };

    configAxios
      .post('/sendEmail/simulation', emailNotification)
      .then((response) => {
        if (response.status === 201) {
          NotificationManager.warning(
            'Não encontramos o formulário de simulações vendas cadastrado, tente novamente!',
            'Atenção'
          );
          return;
        }

        NotificationManager.success('Email enviado com sucesso!', 'Sucesso!');
        props.setOpenModal(false);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para enviar o email, tente novamente!',
          'Atenção'
        );
      });
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
            props.functionGrid();
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'sm'}
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
              props.functionGrid();
            }}>
            Enviar simulação por e-mail
          </BootstrapDialogTitle>
          <form onSubmit={HandleSubmit}>
            <DialogContent dividers>
              {/* <Typography variant="subtitle2" gutterBottom>
                Confira abaixo os emails para enviar a simulação de venda
              </Typography> */}
              <Grid container spacing={2} sx={{ mb: 1 }}>
                <Grid item xs={12} md={6} sx={isMobile ? { mt: 0 } : { mt: 1 }}>
                  <TextField
                    name="sendEmail"
                    fullWidth
                    label="Email"
                    variant="outlined"
                    required
                    size="small"
                    value={infosSimulations.email}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={isMobile ? { mt: 0 } : { mt: 1 }}>
                  <TextField
                    name="sendEmailCopy"
                    fullWidth
                    label="Com cópia (opcional)"
                    variant="outlined"
                    size="small"
                    type="email"
                    value={sendEmailCopy.sendEmailCopy || ''}
                    onChange={(event) => HandleInputChange(event)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="success"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:email-outline" />}>
                Enviar
              </Button>
              <Button
                onClick={() => {
                  props.setOpenModal(false);
                  props.functionGrid();
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Cancelar
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default sendEmailNotification;
