import React, { useState, useRef, useEffect } from 'react';
import useWebSocket from 'react-use-websocket';

import { Box, Divider, Typography, MenuItem, IconButton, Button, Grid } from '@mui/material';
import { selectLogin } from '../../../store/login/loginSlice';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { changeNotification, client } from '../../../store/clients/clientsSlice';

import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import MenuPopover from './MenuPopover';
import configAxios from '../../../services/configAxios';

import NotificationSound from '../../../assets/audio/notification-sound.wav';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -1,
    top: 30,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  },
  right: 5
}));

export default function NotificationBadge() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(null);
  const audioPlayer = useRef(null);

  const wsURL =
    process.env.NODE_ENV === 'production'
      ? 'wss://app.vendasplanoonline.com.br:3014'
      : 'ws://localhost:3014';

  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  const permissions = useSelector((state) => state.login.permissions);
  const dispatch = useDispatch();

  const [ocurrences, setOcurrences] = useState([]);

  const navigation = useNavigate();

  let queryParams = {
    'x-access-token': infoStateTemp.token,
    eventType: 'connection',
    type: 'operadora'
  };

  if (infoState.id_type_user === 2) {
    queryParams = {
      'x-access-token': infoStateTemp.token,
      eventType: 'connection',
      type: 'corretora',
      id_vend: infoState.id
    };
  }

  useWebSocket(wsURL, {
    onMessage: (message) => {
      const data = JSON.parse(message.data);
      if (data.disabledNotification !== 'true') {
        getNotifications();
        playAudio();
      }
    },
    queryParams: queryParams,
    shouldReconnect: () => true,
    reconnectInterval: 3000
  });

  useEffect(() => {
    if (permissions[0].id_type_user !== 3) {
      getNotifications();
    }
  }, []);

  function playAudio() {
    audioPlayer.current.play();
  }

  function getNotifications() {
    let router;

    if (infoState.id_type_user === 1) {
      router = `/notification/getNotifications/?direction=operadora&&id_user=${infoState.id}`;
    } else {
      router = `/notification/getNotifications/?direction=corretora&&id_user=${infoState.id}`;
    }
    configAxios
      .get(router)
      .then((result) => {
        setOcurrences(result.data);
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para buscar as notificações, tente novamente!',
          'Atenção'
        );
      });
  }

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (id) => {
    clearNotification(id);
    setOpen(null);
  };

  function getInfosClient(id_ocorrencia) {
    configAxios
      .get(`/clients/getClientById/${id_ocorrencia}`)
      .then((response) => {
        const nav = `/app/editClients/${id_ocorrencia}`;
        dispatch(changeNotification());
        dispatch(
          client({
            ...response.data
          })
        );
        navigation(nav, {
          state: {
            infosEdit: {
              ...response.data
            }
          }
        });
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações do cliente, tente novamente!',
          'Atenção'
        );
      });
  }

  function clearNotification(id) {
    const router = `/notification/clearNotification/?id_notification=${id}`;
    configAxios
      .put(router)
      .then(() => {
        getNotifications();
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para limpar a notificação, tente novamente!',
          'Atenção'
        );
      });
  }

  function clearNotifications() {
    configAxios
      .put('/notification/clearAllNotifications', ocurrences)
      .then(() => {
        getNotifications();
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para limpar a notificação, tente novamente!',
          'Atenção'
        );
      });
  }

  return (
    <>
      <audio ref={audioPlayer} src={NotificationSound} />
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => {
          setOpen(null);
        }}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75
          },
          overflow: 'auto',
          width: 300,
          maxHeight: 500
        }}>
        <Box sx={{ my: 1.5, px: 1.8 }}>
          <Grid container spacing={2}>
            <Grid item xs={8} md={9} sx={{ mt: 0.5 }}>
              <Typography variant="subtitle2" noWrap>
                Central de notificações
              </Typography>
            </Grid>
            {ocurrences.length > 0 && (
              <Grid item xs={4} md={2}>
                <Button variant="contained" size="small" color="ligth" onClick={clearNotifications}>
                  Limpar
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box>
          <Divider sx={{ borderStyle: 'dashed' }} />
          {ocurrences.length > 0 &&
            ocurrences.map((option) => (
              <div key={option.id}>
                <Divider sx={{ borderStyle: 'dashed' }} />
                <MenuItem
                  key={option.id}
                  component={RouterLink}
                  onClick={() => {
                    handleClose(option.id);
                    getInfosClient(option.id_ocorrencia);
                  }}>
                  Venda #{option.codclient} - {option.ocurrence_date.replaceAll('-', ':')}
                  <br />
                  {option.ocorrencia}
                  <br />
                </MenuItem>
              </div>
            ))}
          {ocurrences.length === 0 && (
            <MenuItem
              onClick={() => {
                setOpen(null);
              }}>
              Não há notificações no momento!
            </MenuItem>
          )}
        </Box>
      </MenuPopover>

      <IconButton ref={anchorRef} onClick={handleOpen} sx={{ mr: 1 }}>
        <StyledBadge badgeContent={ocurrences.length} color="error">
          <NotificationsIcon color="ligth" />
        </StyledBadge>
      </IconButton>
    </>
  );
}
