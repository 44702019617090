import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../../store/login/loginSlice';
import { isMobile } from 'react-device-detect';
import {
  Stack,
  Typography,
  Card,
  Button,
  TextField,
  Grid,
  Box,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';

import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import configAxios from '../../../../services/configAxios';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';

const addEditShortages = (props) => {
  const navigation = useNavigate();
  const location = useLocation();
  const { state, pathname } = location;
  const pathNameSplice = pathname.split('/');
  const [infosShortages, setInfosShortages] = useState(state ? { ...state.infosEdit } : {});

  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  useEffect(() => {
    if (state === null && pathNameSplice[2] === 'editShortages') [navigation('/app/shortages')];
  }, []);

  function HandleInputChange(event) {
    setInfosShortages({ ...infosShortages, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    const router = infosShortages.id ? '/shortages/updateShortages' : '/shortages/addShortages';
    configAxios
      .post(router, infosShortages)
      .then((response) => {
        const message = infosShortages.id
          ? 'Carência atualizada com sucesso!'
          : 'Carência inserida com sucesso!';

        NotificationManager.success(message, 'Sucesso');

        setInfosShortages({
          ...infosShortages,
          id: response.data.id,
          codcarencia: response.data.codcarencia
        });

        const nav = infosShortages.id
          ? `/app/editShortages/${infosShortages.id}`
          : `/app/editShortages/${response.data.id}`;

        navigation(nav, {
          state: {
            infosEdit: {
              ...infosShortages,
              id: infosShortages.id ? infosShortages.id : response.data.id
            }
          }
        });
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }
  return (
    <RenderPage title="Carências" id="Shortages">
      <>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de carências
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para inserir e editar as carências
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/shortages"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <form onSubmit={HandleSubmit}>
              <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 2 }}>
                <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {infosShortages.id && (
                    <Grid item xs={3} md={1}>
                      <TextField
                        name="idproduct"
                        fullWidth
                        label="ID"
                        variant="outlined"
                        size="small"
                        required
                        value={infosShortages.id || ''}
                        disabled
                      />
                    </Grid>
                  )}
                  {infosShortages.codcarencia && (
                    <Grid item xs={6} md={2}>
                      <TextField
                        name="codcarencia"
                        fullWidth
                        label="Código da carência"
                        variant="outlined"
                        size="small"
                        required
                        value={infosShortages.codcarencia || ''}
                        disabled
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="nomecarencia"
                      fullWidth
                      label="Nome da carência"
                      variant="outlined"
                      size="small"
                      disabled={infosShortages.id ? true : false}
                      required
                      value={infosShortages.nomecarencia || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 200 }}
                    />
                  </Grid>
                  {infoState.controlamudancacarencia === 'Sim' && (
                    <Grid item xs={12} md={2}>
                      <InputLabel
                        id="label"
                        sx={
                          isMobile ? { fontSize: '13px', mt: 0 } : { fontSize: '13px', mt: -2.2 }
                        }>
                        É carência de pré existência? *
                      </InputLabel>
                      <Select
                        name="carenciapreexistencia"
                        fullWidth
                        size="small"
                        required
                        value={infosShortages.carenciapreexistencia || ''}
                        onChange={(event) => HandleInputChange(event)}>
                        <MenuItem value={'Sim'}>Sim</MenuItem>
                        <MenuItem value={'Não'}>Não</MenuItem>
                      </Select>
                    </Grid>
                  )}
                  {infosShortages.carenciapreexistencia === 'Sim' && (
                    <Grid item xs={12} md={2}>
                      <InputLabel
                        id="label"
                        sx={
                          isMobile ? { fontSize: '13px', mt: 0 } : { fontSize: '13px', mt: -2.2 }
                        }>
                        Considera o grau de obesidade? *
                      </InputLabel>
                      <Select
                        name="consideragrauobesidade"
                        fullWidth
                        size="small"
                        required
                        value={infosShortages.consideragrauobesidade || ''}
                        onChange={(event) => HandleInputChange(event)}>
                        <MenuItem value={'Sim'}>Sim</MenuItem>
                        <MenuItem value={'Não'}>Não</MenuItem>
                      </Select>
                    </Grid>
                  )}
                  {infoState.controlamudancacarencia === 'Sim' &&
                    infosShortages.carenciapreexistencia === 'Não' && (
                      <Grid item xs={12} md={2}>
                        <InputLabel
                          id="label"
                          sx={
                            isMobile ? { fontSize: '13px', mt: 0 } : { fontSize: '13px', mt: -2.2 }
                          }>
                          Permite mudança? *
                        </InputLabel>
                        <Select
                          name="permitemudanca"
                          fullWidth
                          size="small"
                          required
                          value={infosShortages.permitemudanca || ''}
                          onChange={(event) => HandleInputChange(event)}>
                          <MenuItem value={'Sim'}>Sim</MenuItem>
                          <MenuItem value={'Não'}>Não</MenuItem>
                        </Select>
                      </Grid>
                    )}
                  {infoState.tipointegracao !== 'Não possui' && (
                    <Grid item xs={12} md={2}>
                      <TextField
                        name="codext"
                        fullWidth
                        label="Código externo"
                        variant="outlined"
                        size="small"
                        required
                        value={infosShortages.codext || ''}
                        onChange={(event) => HandleInputChange(event)}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="success"
                  // disabled={infosShortages.id ? true : false}
                  sx={{ mr: 2 }}
                  startIcon={<Iconify icon="eva:save-fill" />}>
                  {infosShortages.id ? 'Atualizar' : 'Salvar'}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to="/app/shortages"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </Stack>
            </form>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default addEditShortages;
