import { useRef, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { Box, Divider, Typography, MenuItem, Avatar, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/login/loginSlice';
import { styled } from '@mui/material/styles';

import Badge from '@mui/material/Badge';
import MenuPopover from '../components/multiplesComponents/MenuPopover';

const MENU_OPTIONS = [
  {
    label: 'Minha conta',
    linkTo: '/app/myAccount'
  }
];

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  }
}));

export default function AccountPopover() {
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(null);

  const client = useSelector((state) => state.login.clientLogin);
  const user = useSelector((state) => state.login.authenticatedUser);

  const permissions = useSelector((state) => state.login.permissions);
  const routerLogout = permissions[0].id_type_user === 3 ? `/portal/${client}` : `/${client}`;

  let photoURL;

  photoURL = user.userLoggedIn[0].avatar
    ? user.userLoggedIn[0].avatar
    : '/static/media/avatar1.68d7bc8804ba2762d8bf.jpeg';

  function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name)
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1] ? name.split(' ')[1][0] : ''}`
    };
  }

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  function Logout() {
    dispatch(logout());
    handleClose();
  }

  return (
    <>
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot">
        <IconButton ref={anchorRef} onClick={handleOpen} sx={{ p: 0 }}>
          {permissions[0].id_type_user === 3 && (
            <Avatar
              {...stringAvatar(user.userLoggedIn[0].nome)}
              alt="avatar"
              sx={{ width: 56, height: 56 }}
            />
          )}
          {permissions[0].id_type_user !== 3 && (
            <Avatar src={photoURL} alt="avatar" sx={{ width: 56, height: 56 }} />
          )}
        </IconButton>
      </StyledBadge>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75
          },
          overflow: 'inherit',
          width: 200
        }}>
        <Box sx={{ my: 1.5, px: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.userLoggedIn[0].nome}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.userLoggedIn[0].email}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        {permissions[0].id_type_user !== 3 && (
          <Box>
            {MENU_OPTIONS.map((option) => (
              <MenuItem
                key={option.label}
                to={option.linkTo}
                component={RouterLink}
                onClick={handleClose}>
                {option.label}
              </MenuItem>
            ))}
          </Box>
        )}
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem to={routerLogout} onClick={Logout} component={RouterLink}>
          Sair
        </MenuItem>
      </MenuPopover>
    </>
  );
}
