import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';

import PropTypes from 'prop-types';

function renderTitle() {
  const userAuth = useSelector((state) => state.login.authenticatedUser);

  if (userAuth !== 'undefined' && userAuth.length !== 0) {
    return userAuth.userLoggedIn[0].nomefantasia;
  }

  return 'Mplan Sistemas e Consultorias';
}
// eslint-disable-next-line react/display-name
const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`${title} | ${renderTitle()}`}</title>
      {meta}
    </Helmet>
    <Container ref={ref} {...other} maxWidth="xl">
      {children}
    </Container>
  </>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node
};

export default Page;
