import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { fShortenNumber } from '../../../../@core/utils/formatNumber';

import PropTypes from 'prop-types';
import Iconify from '../../../../@core/theme/Iconify';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3)
}));

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object
};

export default function AppWidgetSummary({ title, total, icon, color = 'primary', sx, ...other }) {
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: 'white',
        bgcolor: (theme) => theme.palette[color].main,
        ...sx
      }}
      {...other}>
      <IconWrapperStyle
        sx={{
          color: 'white',
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].light, 0)} 0%, ${alpha(
              theme.palette[color].light,
              0.24
            )} 100%)`
        }}>
        <Iconify icon={icon} width={46} height={46} />
      </IconWrapperStyle>
      <Typography variant="h3">{fShortenNumber(total)}</Typography>
      <Typography variant="subtitle2">{title}</Typography>
    </Card>
  );
}
