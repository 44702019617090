import React from 'react';

import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

import NotificationBadge from '../components/multiplesComponents/NotificationBadge';
import Search from '../components/multiplesComponents/Search';
import Iconify from '../../@core/theme/Iconify';
import AccountPopover from './AccountPopover';
import pallete from '../../@core/theme/palette';
import PropTypes from 'prop-types';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 65;
const APPBAR_DESKTOP = 65;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  backgroundColor: pallete.primary.main,

  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    backgroundColor: pallete.primary.main,
    margin: 5,
    borderRadius: 10
  }
}));

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const permissions = useSelector((state) => state.login.permissions);

  async function recordDisplay() {
    const stream = await navigator.mediaDevices.getDisplayMedia();
    const recoder = new MediaRecorder(stream);

    recoder.start();

    const [video] = stream.getVideoTracks();
    video.addEventListener('ended', () => {
      recoder.stop();
    });

    recoder.addEventListener('dataavailable', (evt) => {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(evt.data);
      a.download = 'vendas-captura-de-video.webm';
      a.click();
    });
  }

  function RenderShortcodes() {
    const itemsShortcodes = [];

    permissions.map((item) => {
      if (item) {
        if (item.path === '/app/dashboard') {
          itemsShortcodes.push(
            <div key={item.path}>
              <IconButton
                sx={{ mr: 1, color: '#FFFFFF' }}
                component={RouterLink}
                to="/app/dashboard">
                <Iconify icon="eva:home-outline" />
              </IconButton>
            </div>
          );
        }
        if (item.path === '/app/users') {
          itemsShortcodes.push(
            <div key={item.path}>
              <IconButton
                sx={{ mr: 1, color: '#FFFFFF' }}
                key={item.path}
                component={RouterLink}
                to="/app/users">
                <Iconify icon="eva:people-fill" />
              </IconButton>
            </div>
          );
        }
        if (item.path === '/app/clients') {
          itemsShortcodes.push(
            <div key={item.path}>
              <IconButton
                sx={{ mr: 1, color: '#FFFFFF' }}
                key={item.path}
                component={RouterLink}
                to="/app/clients">
                <Iconify icon="eva:person-add-outline" />
              </IconButton>
            </div>
          );
        }
        if (item.path === '/app/forms') {
          itemsShortcodes.push(
            <div key={item.path}>
              <IconButton
                sx={{ mr: 1, color: '#FFFFFF' }}
                key={item.path}
                component={RouterLink}
                to="/app/forms">
                <Iconify icon="eva:edit-outline" />
              </IconButton>
            </div>
          );
        }
        if (item.path === '/app/simulations') {
          itemsShortcodes.push(
            <div key={item.path}>
              <IconButton
                sx={{ mr: 1, color: '#FFFFFF' }}
                component={RouterLink}
                key={item.path}
                to="/app/simulations">
                <Iconify icon="ant-design:exception-outlined" />
              </IconButton>
            </div>
          );
        }
        if (item.path === '/app/reports/repSales') {
          itemsShortcodes.push(
            <div key={item.path}>
              <IconButton
                sx={{ mr: 1, color: '#FFFFFF' }}
                component={RouterLink}
                key={item.path}
                to="/app/reports/repSales">
                <Iconify icon="eva:activity-outline" />
              </IconButton>
            </div>
          );
        }
      }
    });
    return itemsShortcodes;
  }

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: '#FFFFFF', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        {!isMobile && (
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.0 }}>
            <RenderShortcodes />
            {permissions[0].id_type_user !== 3 && (
              <IconButton sx={{ mr: 1, color: '#FFFFFF' }} onClick={recordDisplay}>
                <Iconify icon="eva:video-outline" />
              </IconButton>
            )}
          </Stack>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {!isMobile && permissions[0].id_type_user !== 3 && <Search />}
          {permissions[0].id_type_user !== 3 && (
            <>
              <NotificationBadge />
            </>
          )}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
