/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BootstrapDialog, TextareaAutosize } from '../../../../assets/style/globalStyle';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../../store/login/loginSlice';
import { NotificationManager } from 'react-notifications';
import { startAndStop } from '../../../../store/loading/loadingSlice';

import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  InputLabel,
  Grid,
  Autocomplete,
  TextField
} from '@mui/material';

import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import axios from 'axios';

const SendMessageWhatsApp = (props) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [messages, setMessages] = useState([]);
  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  useEffect(() => {
    fetchMessages();
  }, []);

  function fetchMessages() {
    const urlIntegration = infoState.rotapostback;
    axios
      .get(`${urlIntegration}/messages/sendMessages`, {
        headers: {
          authorization: `Bearer ${infoState.tokenpostback}`
        }
      })
      .then((response) => {
        setMessages(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as mensagens, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleDispatchMessage() {
    const urlIntegration = infoState.rotapostback;

    const postObject = {
      sendInfosBenef: props.sendInfosBenef,
      typeSend: props.inputRadio,
      message: message.message
    };

    dispatch(startAndStop(true));

    axios
      .post(`${urlIntegration}/messages/dispatchMessage`, postObject, {
        headers: {
          authorization: `Bearer ${infoState.tokenpostback}`
        }
      })
      .then(() => {
        dispatch(startAndStop(false));
        NotificationManager.success('Mensagens enviados com sucesso!', 'Sucesso');
      })
      .catch(() => {
        dispatch(startAndStop(false));
        NotificationManager.error(
          'Tivemos dificuldades para enviar as mensagens, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleInputChange(event, params = {}) {
    if (params.autocomplete) {
      if (event) {
        setMessage({ ...message, label: event.label, message: event.mensagem });
        return;
      }
      return;
    }
    setMessage({ ...message, [event.target.name]: event.target.value });
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };
  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => props.setOpenModal(false)}
          aria-labelledby="customized-dialog-title"
          open={props.openModal}
          fullWidth
          maxWidth={'sm'}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => props.setOpenModal(false)}>
            Mensagem no WhatsApp
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid item xs={12} md={2} mb={2}>
              <InputLabel id="label" sx={{ fontSize: '13px' }}>
                Selecione a mensagem:
              </InputLabel>
              <Autocomplete
                options={messages}
                renderOption={(props, option) => <li {...props}>{option.label}</li>}
                isOptionEqualToValue={(option, value) =>
                  value ? option.label === value : option.label === ''
                }
                onChange={(event, value) => HandleInputChange(value, { autocomplete: true })}
                value={message.label || ''}
                defaultValue=""
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="messageSelect"
                    fullWidth
                    size="small"
                    variant="outlined"
                    required
                  />
                )}
              />
            </Grid>
            <InputLabel id="label" sx={{ fontSize: '13px', ml: 0.3 }}>
              Mensagem a ser enviada:
            </InputLabel>
            <TextareaAutosize
              name="message"
              label="Mensagem a ser enviada:"
              size="small"
              variant="outlined"
              minRows={3}
              value={message.message || ''}
              onChange={(event) => HandleInputChange(event)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => HandleDispatchMessage()}
              color="success"
              variant="contained"
              startIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}>
              Confirmar
            </Button>
            <Button
              onClick={() => props.setOpenModal(false)}
              color="error"
              variant="contained"
              startIcon={<Iconify icon="eva:close-circle-fill" />}>
              Cancelar
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default SendMessageWhatsApp;
