import PropTypes from 'prop-types';
import { useMemo } from 'react';
// material
import { CssBaseline } from '@mui/material';

import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider
} from '@mui/material/styles';
//
import palette from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import { useSelector } from 'react-redux';

ThemeProvider.propTypes = {
  children: PropTypes.node
};

export default function ThemeProvider({ children }) {
  const userAuth = useSelector((state) => state.login.authenticatedUser);
  if (userAuth !== 'undefined' && userAuth.length !== 0) {
    palette.primary.main = userAuth.userLoggedIn[0].corprimaria
      ? `${userAuth.userLoggedIn[0].corprimaria}`
      : '#1666FD';
    palette.primary.dark = userAuth.userLoggedIn[0].corprimaria
      ? `${userAuth.userLoggedIn[0].corprimaria}150`
      : '#013392';
  }
  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 8 },
      typography,
      shadows,
      customShadows
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
