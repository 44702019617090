/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../../store/login/loginSlice';
import { IconButton, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing,
  Selection
} from 'devextreme-react/data-grid';

import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import axios from 'axios';

const allowedPageSizes = [5, 10];

const FetchBenefs = (props) => {
  const [infosBenef, setInfosBenef] = useState();
  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  useEffect(() => {
    fetchBenefs();
  }, []);

  function fetchBenefs() {
    const urlIntegration = infoState.rotapostback;
    axios
      .get(`${urlIntegration}/users/getUsers`, {
        headers: {
          authorization: `Bearer ${infoState.tokenpostback}`
        }
      })
      .then((response) => {
        setInfosBenef(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os beneficiários, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  function selectedRow(event) {
    props.setOpenModal(false);
    props.setInfosBenef(event.selectedRowsData[0]);
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };
  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'md'}
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
            }}>
            Buscar beneficiários
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <DataGrid
              dataSource={infosBenef}
              showBorders={true}
              remoteOperations={true}
              wordWrapEnabled={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              allowExportSelectedData={true}
              onSelectionChanged={selectedRow}
              noDataText={'Não foram encontrados resultados'}>
              <Column dataField="codtit" caption="Código do titular" />
              <Column dataField="codusu" caption="Código do beneficiário" />
              <Column dataField="nomeusu" caption="Nome do beneficiário" />
              <SearchPanel visible={true} width={240} placeholder="Procurar..." />
              <GroupPanel visible={true} emptyPanelText="Arraste as colunas aqui para agrupar" />
              <Scrolling rowRenderingMode="virtual"></Scrolling>
              <Selection mode="single" />
              <Paging defaultPageSize={5} />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              <ColumnChooser
                enabled={true}
                title={'Escolher uma coluna'}
                emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
              />
              <ColumnFixing enabled={true} />
              <HeaderFilter visible={true} allowSearch={true} />
            </DataGrid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                props.setOpenModal(false);
              }}
              color="error"
              startIcon={<Iconify icon="eva:close-circle-fill" />}
              variant="contained">
              Fechar
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default FetchBenefs;
