const permissions = () => [
  {
    ordem: 0,
    name: 'Dashboard',
    path: '/app/dashboard',
    typeUser: 'corretora'
  },
  {
    ordem: 1,
    name: 'Assinaturas',
    path: '/app/signature',
    typeUser: 'client'
  },
  {
    ordem: 2,
    name: 'Usuários',
    path: '/app/users',
    typeUser: 'corretora'
  },
  {
    ordem: 3,
    name: 'Permissões padrões',
    path: '/app/permissions',
    typeUser: 'operadora'
  },
  {
    ordem: 4,
    name: 'Minha conta',
    path: '/app/myAccount',
    typeUser: 'corretora'
  },
  {
    ordem: 5,
    name: 'Clientes',
    path: '/app/clients',
    typeUser: 'corretora'
  },
  {
    ordem: 6,
    name: 'Simulações',
    path: '/app/simulations',
    typeUser: 'corretora'
  },
  {
    ordem: 7,
    name: 'Operadoras',
    path: '/app/operators',
    typeUser: 'administradora'
  },
  {
    ordem: 8,
    name: 'Corretoras',
    path: '/app/brokers',
    typeUser: 'operadora'
  },
  {
    ordem: 9,
    name: 'Associações',
    path: '/app/associations',
    typeUser: 'operadora'
  },
  {
    ordem: 10,
    name: 'Avisos',
    path: '/app/warnings',
    typeUser: 'operadora'
  },
  {
    ordem: 11,
    name: 'Formulários',
    path: '/app/forms',
    typeUser: 'operadora'
  },
  {
    ordem: 12,
    name: 'Declaração de saúde',
    path: '/app/declarationHealth',
    typeUser: 'operadora'
  },
  {
    ordem: 13,
    name: 'Produtos',
    path: '/app/products',
    typeUser: 'operadora'
  },
  {
    ordem: 14,
    name: 'Tabelas',
    path: '/app/tables',
    typeUser: 'operadora'
  },
  {
    ordem: 15,
    name: 'Reajustes',
    path: '/app/readjustments',
    typeUser: 'operadora'
  },
  {
    ordem: 16,
    name: 'Carências',
    path: '/app/shortages',
    typeUser: 'operadora'
  },
  {
    ordem: 17,
    name: 'Relatório de vendas',
    path: '/app/reports/repSales',
    typeUser: 'corretora'
  },
  {
    ordem: 18,
    name: 'Relatório de simulações',
    path: '/app/reports/repSimulations',
    typeUser: 'corretora'
  },
  {
    ordem: 19,
    name: 'Comissões',
    path: '/app/comissions',
    typeUser: 'corretora'
  },
  {
    ordem: 20,
    name: 'Configurações de email',
    path: '/app/email',
    typeUser: 'operadora'
  },
  {
    ordem: 22,
    name: 'Configurações do sistema',
    path: '/app/config',
    typeUser: 'operadora'
  }
];

export default permissions;
