import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../../store/login/loginSlice';
import { isMobile } from 'react-device-detect';
import { useDebounce } from 'use-debounce';
import { validateCNPJ, validateCPF, viaCNPJ } from '../../../../@core/utils/utilsFunctions';
import {
  Stack,
  Typography,
  Card,
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  Autocomplete,
  InputLabel,
  Box
} from '@mui/material';
import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

import AddEditRangeSimulations from './AddEditRangeSimulations';
import SendEmailNotification from './SendEmailNotification';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import InputMask from 'react-input-mask';
import MoreMenu from '../../../components/multiplesComponents/MoreMenu';
import configAxios from '../../../../services/configAxios';
import Iconify from '../../../../@core/theme/Iconify';
import RenderPage from '../../../layouts/RenderPage';

/**
 * id_type_client === 1 - Físico
 * id_type_client === 2 - Júridico
 *
 */

const AddEditSimulations = (props) => {
  const navigation = useNavigate();
  const location = useLocation();
  const { state, pathname } = location;
  const pathNameSplice = pathname.split('/');

  const [infosSimulations, setInfosSimulations] = useState(state ? { ...state.infosEdit } : {});
  const [values, setValues] = useState([]);
  const [infosEditSimulations, setInfosEditSimulations] = useState({});
  const [initialInfosSimulations] = useState(state ? { ...state.infosEdit } : {});
  const [infosOrgaEmissor, setInfosOrgaEmissor] = useState([]);
  const [infosPlans, setInfosPlans] = useState([]);
  const [infosTables, setInfosTables] = useState([]);
  const [infosTablesTemp, setInfosTablesTemp] = useState([]);

  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });
  const [infosAssociations, setInfosAssociation] = useState([]);

  const [infoSelectVend, setInfoSelectVend] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalEmailNotification, setOpenModalEmailNotification] = useState(false);

  const key = useRef('');
  const [value] = useDebounce(key.current, 1000);

  useEffect(() => {
    if (state === null && pathNameSplice[2] === 'editSimulations') [navigation('/app/simulations')];

    if (pathNameSplice[2] === 'addSimulations' && infoState.id_type_user !== 2)
      [navigation('/app/simulations')];

    if (infosSimulations.tpcontrat_client !== '3-COLETIVO POR ADESAO') {
      getPlans(infosSimulations.tpcontrat_client);
      getTables();
    } else {
      getPlansAssociation(infosSimulations.id_association, infosSimulations.id_broker);
      getTablesAssociation(infosSimulations.id_association);
    }
    Promise.all([getInfoSelectVend(), getInfosOrgaoEmissor(), getAssociations()]);
  }, [infosSimulations.id]);

  useEffect(() => {
    if (infosSimulations.id) {
      getValues();
    }
  }, []);

  function getInfoSelectVend() {
    if (infosSimulations.id) {
      let idGetVend = infosSimulations.id_broker ? infosSimulations.id_broker : infoState.id_broker;
      configAxios
        .get(`/brokers/listVendLabel/${idGetVend}`)
        .then((response) => {
          setInfoSelectVend(response.data);
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para buscar a lista de vendedores! Tente novamente!',
            'Atenção'
          );
        });
    }
  }

  function getInfosOrgaoEmissor() {
    configAxios
      .get('utilsInfos/getInfosOrgaoEmissor')
      .then((response) => {
        setInfosOrgaEmissor(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações do RG! Tente novamente!',
          'Atenção'
        );
      });
  }

  function getPlans(tpcontrat = undefined) {
    let id_broker = infosSimulations.id_broker ? infosSimulations.id_broker : infoState.id_broker;
    let codcontratotpcontrat;
    if (tpcontrat) {
      codcontratotpcontrat = '01';

      if (tpcontrat === '2-COLETIVO EMPRESARIAL') {
        codcontratotpcontrat = '02';
      }

      if (tpcontrat === '3-COLETIVO POR ADESAO') {
        codcontratotpcontrat = '03';
      }
    }
    configAxios
      .get(`products/getPlansLabel/${id_broker}/${codcontratotpcontrat}`)
      .then((response) => {
        setInfosPlans(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações do Plano! Tente novamente!',
          'Atenção'
        );
      });
  }

  function getTables() {
    let id_broker = infosSimulations.id_broker ? infosSimulations.id_broker : infoState.id_broker;
    configAxios
      .get(`/tables/getTablesLabel/${id_broker}`)
      .then((response) => {
        setInfosTables(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações das tabelas! Tente novamente!',
          'Atenção'
        );
      });
  }

  function getValues() {
    configAxios
      .get(`/simulations/listValues/${infosSimulations.id}`)
      .then((result) => {
        setValues(result.data);
        calculateValuesForSimulations(result.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os valores da simulação! Tente novamente!',
          'Atenção'
        );
      });
  }

  async function getAssociations() {
    let id_broker = infosSimulations.id_broker ? infosSimulations.id_broker : infoState.id_broker;
    await configAxios
      .get(`/association/listAssociationLabel/${id_broker}`)
      .then((response) => {
        setInfosAssociation(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações das associações! Tente novamente!',
          'Atenção'
        );
      });
  }

  function ActionsButtons(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          deleteRouter: '/simulations/deleteValues',
          deleteItem: 'o registro',
          functionGrid: getValues,
          functionModal: addRangeSimulations
        }}
      />
    ));
  }

  function addRangeSimulations(id) {
    if (id) {
      const valuesEdit = values.filter((element) => element.id === id);
      setInfosEditSimulations(valuesEdit);
    }
    setOpenModal(true);
  }

  function sendEmailNotification() {
    setOpenModalEmailNotification(true);
  }

  function downloadSimulation() {
    const downloadObject = {
      infosAddEdit: infosSimulations,
      values
    };

    configAxios
      .post('/simulations/downloadSimulation', downloadObject, { responseType: 'blob' })
      .then((response) => {
        if (response.status === 201) {
          NotificationManager.warning(
            'Não encontramos o formulário de vendas cadastrado, tente novamente!',
            'Atenção'
          );
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Proposta plano de saúde.pdf');
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para gerar a proposta! Tente novamente!',
          'Atenção'
        );
      });
  }

  function validCPF(event) {
    if (event.target.value) {
      const cpfIsValid = validateCPF(event.target.value);
      if (!cpfIsValid) {
        NotificationManager.warning('CPF inválido, por favor verifique!', 'Atenção');
        setInfosSimulations({ ...infosSimulations, cpf_cnpj: '' });
        return;
      }
      setInfosSimulations({ ...infosSimulations, [event.target.name]: event.target.value });
    }
  }

  async function validCNPJ(event) {
    if (event.target.value) {
      const cnpjIsValid = validateCNPJ(event.target.value);
      if (!cnpjIsValid) {
        NotificationManager.warning('CNPJ inválido, por favor verifique!', 'Atenção');
        setInfosSimulations({ ...infosSimulations, cpf_cnpj: '' });
        return;
      }
      let infosCNPJ = false;
      if (!infosSimulations.nome && !infosSimulations.nomefantasia) {
        infosCNPJ = await viaCNPJ(event.target.value);
      }
      if (infosCNPJ) {
        setInfosSimulations({
          ...infosSimulations,
          ['nome']: infosCNPJ.nome,
          ['nomefantasia']: infosCNPJ.nomefantasia,
          [event.target.name]: event.target.value
        });
        return;
      }
      setInfosSimulations({ ...infosSimulations, [event.target.name]: event.target.value });
    }
  }

  function validDtnascimento() {
    if (
      infosSimulations.dtnascimento > new Date().toLocaleDateString().split('/').reverse().join('-')
    ) {
      NotificationManager.warning('Por favor, escolha uma data de nascimento válida!', 'Atenção');
      infosSimulations.dtnascimento = '';
    }
    return;
  }

  function HandleInputChange(event, params = {}) {
    if (params.autocomplete) {
      if (event) {
        setInfosSimulations({ ...infosSimulations, label: event.label, id_orgao: event.id });
        return;
      }
      return;
    }
    if (params.plan) {
      if (event) {
        const infosTablesTemp = infosTables.filter(
          (element) => element.codplano === event.codplano
        );
        setInfosTablesTemp([]);
        infosTablesTemp.unshift({ id: '', label: '' });
        setInfosTablesTemp(infosTablesTemp);
        infosSimulations.labeltables = '';
        infosSimulations.codtab = '';
        setInfosSimulations({
          ...infosSimulations,
          labelplans: event.label,
          codplano: event.codplano
        });
        if (infosSimulations.id) {
          NotificationManager.warning(
            'Ao alterar o plano e salvar, os planos dos titulares e dependentes serão recalculados com base no novo plano/tabela!',
            'Atenção'
          );
        }
      }
      return;
    }
    if (params.table) {
      if (event) {
        setInfosSimulations({
          ...infosSimulations,
          labeltables: event.label,
          codtab: event.codtab
        });
        if (infosSimulations.id) {
          NotificationManager.warning(
            'Ao alterar a tabela e salvar, os planos dos titulares e dependentes serão recalculados com base no novo plano/tabela!',
            'Atenção'
          );
        }
        return;
      }
      return;
    }
    if (params.cep) {
      setInfosSimulations({ ...infosSimulations, ...params.cep });
      return;
    }
    if (params.vend) {
      setInfosSimulations({ ...infosSimulations, labelvend: event.label, id_user: event.id });
      return;
    }
    if (params.association) {
      if (event) {
        clearPlansAndTables();

        setInfosSimulations({
          ...infosSimulations,
          labelassociation: event.label,
          id_association: event.id_association
        });

        getPlansAssociation(event.id_association, infosSimulations.id_broker);
        getTablesAssociation(event.id_association);

        return;
      }
      return;
    }

    if (event.target.name === 'tpcontrat_client') {
      clearPlansAndTables();

      if (event.target.value === '3-COLETIVO POR ADESAO') {
        if (infosSimulations.id_type_client !== 1) {
          NotificationManager.warning(
            'Só é possível vincular o tipo de contratação coletivo por adesão a clientes pessoa física!',
            'Atenção'
          );
          return;
        }

        setInfosSimulations({ ...infosSimulations, [event.target.name]: event.target.value });
      } else {
        if (
          infosSimulations.id_type_client !== 1 &&
          event.target.value === '1-INDIVIDUAL/FAMILIAR'
        ) {
          NotificationManager.warning(
            'Só é possível vincular o tipo de contratação invidual/familiar a clientes pessoa física!',
            'Atenção'
          );
          return;
        }

        if (
          infosSimulations.id_type_client === 1 &&
          event.target.value === '2-COLETIVO EMPRESARIAL'
        ) {
          NotificationManager.warning(
            'Só é possível vincular o tipo de contratação coletivo empresarial a clientes pessoa jurídica!',
            'Atenção'
          );
          return;
        }

        setInfosSimulations({ ...infosSimulations, [event.target.name]: event.target.value });
        getTables();
        getPlans(event.target.value);
      }

      return;
    }

    if (event.target.name === 'id_type_client') {
      infosSimulations.tpcontrat_client = '';
    }
    setInfosSimulations({ ...infosSimulations, [event.target.name]: event.target.value });
  }

  function clearPlansAndTables() {
    setInfosTablesTemp([]);
    setInfosTables([]);
    setInfosPlans([]);
    infosSimulations.labeltables = '';
    infosSimulations.codtab = '';
    infosSimulations.labeltables = '';
    infosSimulations.labelplans = '';
    infosSimulations.codplano = '';
  }

  function getPlansAssociation(id_association, id_broker) {
    const idBroker = id_broker ? id_broker : infoState.id_broker;
    configAxios
      .get(`products/getPlansAssociationLabel/${id_association}/${idBroker}`)
      .then((response) => {
        setInfosPlans(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações do Plano! Tente novamente!',
          'Atenção'
        );
      });
  }

  async function getTablesAssociation(id_association) {
    await configAxios
      .get(`/tables/getTablesAssociationLabel/${id_association}`)
      .then((response) => {
        setInfosTables(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações das tabelas! Tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleSubmit(event) {
    event.preventDefault();

    const infosAddEdit = {
      ...infosSimulations
    };
    let infosEdit = {};

    infosSimulations.id_user = infosSimulations.id_user ? infosSimulations.id_user : infoState.id;
    infosAddEdit.id_user = infosSimulations.id_user ? infosSimulations.id_user : infoState.id;

    if (!infosSimulations.id_user) {
      NotificationManager.warning('Por favor, selecione um vendedor', 'Atenção');
      return;
    }

    if (infosSimulations.id) {
      for (let key in infosAddEdit) {
        if (initialInfosSimulations[key] === infosAddEdit[key]) {
          if (key !== 'id' && key !== 'codtab' && key !== 'id_user') delete infosAddEdit[key];
        }
      }
    }

    const router = infosSimulations.id
      ? 'simulations/updateSimulation'
      : 'simulations/addSimulation';
    configAxios
      .post(router, { infosAddEdit })
      .then((response) => {
        if (response.status === 201) {
          NotificationManager.warning(
            'A simulação foi cadastrada com sucesso, mas tivemos dificuldades para buscar o vendedor, tente novamente!',
            'Atenção'
          );
        }
        NotificationManager.success(
          infosSimulations.id
            ? 'Simulação atualizada com sucesso!'
            : 'Simulação realizada com sucesso!',
          'Sucesso'
        );

        if (infosSimulations.id) {
          for (let key in infosAddEdit) {
            if (initialInfosSimulations[key] !== infosAddEdit[key]) {
              initialInfosSimulations[key] = infosAddEdit[key];
            }
          }
          setInfosSimulations({
            ...initialInfosSimulations,
            id: response.data.id,
            labelvend: response.data.labelvend
              ? response.data.labelvend
              : infosSimulations.labelvend,
            datacadastro: response.data.datacadastro
          });

          infosEdit = {
            ...initialInfosSimulations,
            id: infosSimulations.id ? infosSimulations.id : response.data.id
          };
        } else {
          setInfosSimulations({
            ...infosSimulations,
            id: response.data.id,
            labelvend: response.data.labelvend
              ? response.data.labelvend
              : infosSimulations.labelvend,
            datacadastro: response.data.datacadastro
          });

          infosEdit = {
            ...infosSimulations,
            id: infosSimulations.id ? infosSimulations.id : response.data.id
          };
        }

        const nav = infosSimulations.id
          ? `/app/editSimulations/${infosSimulations.id}`
          : `/app/editSimulations/${response.data.id}`;

        if (router === 'simulations/updateSimulation') getValues();

        key.current = Math.random();

        navigation(nav, {
          state: { infosEdit }
        });
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function calculateValuesForSimulations(infosForCalculate) {
    let valuesSimulations = 0;
    infosForCalculate.map((element) => {
      valuesSimulations += +element.valsimulation;
    });

    setInfosSimulations({
      ...infosSimulations,
      valueTotal: Number(valuesSimulations).toFixed(2)
    });
  }

  return (
    <RenderPage title="Simulações">
      <>
        <AddEditRangeSimulations
          openModal={openModal}
          infosEdit={infosEditSimulations}
          otherInfos={infosSimulations}
          setOpenModal={setOpenModal}
          functionGrid={getValues}
        />

        {infosSimulations.id && (
          <SendEmailNotification
            openModal={openModalEmailNotification}
            infosSimulations={infosSimulations}
            values={values}
            key={value}
            functionGrid={getValues}
            setOpenModal={setOpenModalEmailNotification}
          />
        )}
        {isMobile && (
          <>
            <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
              <Grid container>
                <BreadcrumbsNav {...props} />
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Typography variant="h4" gutterBottom>
                    Cadastro de simulações de vendas
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" gutterBottom>
                    Utilize as opções abaixo para inserir e editar as simulações de vendas
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="right" mb={2}>
              {infosSimulations.id && (
                <>
                  <Button
                    sx={{ mr: 1 }}
                    variant="contained"
                    color="primary"
                    onClick={downloadSimulation}
                    startIcon={<Iconify icon="eva:cloud-download-outline" />}>
                    Download
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={sendEmailNotification}
                    startIcon={<Iconify icon="eva:email-outline" />}>
                    Enviar
                  </Button>
                </>
              )}
            </Stack>
          </>
        )}
        {!isMobile && (
          <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
            <Grid container>
              <BreadcrumbsNav {...props} />
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="h4" gutterBottom>
                  Cadastro de simulações de vendas
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Utilize as opções abaixo para inserir e editar as simulações de vendas
                </Typography>
              </Grid>
            </Grid>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              {infosSimulations.id && (
                <>
                  <Button
                    sx={{ mr: 1 }}
                    variant="contained"
                    color="primary"
                    onClick={downloadSimulation}
                    startIcon={<Iconify icon="eva:cloud-download-outline" />}>
                    Download
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={sendEmailNotification}
                    startIcon={<Iconify icon="eva:email-outline" />}>
                    Enviar
                  </Button>
                </>
              )}
              {!isMobile && (
                <Button
                  variant="outlined"
                  color="error"
                  component={RouterLink}
                  to="/app/simulations"
                  sx={{ ml: 1 }}
                  startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                  Voltar
                </Button>
              )}
            </Stack>
          </Stack>
        )}
        <Card>
          <CustomGrid>
            <form onSubmit={HandleSubmit}>
              <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 2 }}>
                <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  {infosSimulations.id && (
                    <Grid item xs={3} md={1} sx={isMobile ? { mt: 1.5 } : { mt: 4.2 }}>
                      <TextField
                        name="id"
                        fullWidth
                        label="ID"
                        variant="outlined"
                        size="small"
                        required
                        disabled
                        value={infosSimulations.id || ''}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={2} sx={isMobile ? { mt: 0 } : { mt: 2 }}>
                    <InputLabel id="label" sx={{ fontSize: '13px' }}>
                      Tipo de cliente *
                    </InputLabel>
                    <Select
                      name="id_type_client"
                      fullWidth
                      size="small"
                      required
                      disabled={infosSimulations.id ? true : false}
                      value={infosSimulations.id_type_client || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={1}>Físico</MenuItem>
                      <MenuItem value={2}>Jurídico</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 1 }}>
                  <Grid item xs={12} md={3}>
                    <InputLabel id="label" sx={{ fontSize: '13px', mt: 2 }}>
                      Tipo de contratação *
                    </InputLabel>
                    <Select
                      name="tpcontrat_client"
                      fullWidth
                      size="small"
                      required
                      value={infosSimulations.tpcontrat_client || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={'1-INDIVIDUAL/FAMILIAR'}>1-INDIVIDUAL/FAMILIAR</MenuItem>
                      <MenuItem value={'2-COLETIVO EMPRESARIAL'}>2-COLETIVO EMPRESARIAL</MenuItem>
                      <MenuItem value={'3-COLETIVO POR ADESAO'}>3-COLETIVO POR ADESÃO</MenuItem>
                    </Select>
                  </Grid>
                  {infoState.controlacaepf === 'Sim' && infosSimulations.id_type_client === 2 && (
                    <Grid item xs={12} md={2} sx={isMobile ? {} : { mt: 2 }}>
                      <InputLabel id="label" sx={{ fontSize: '13px' }}>
                        Tipo de documento *
                      </InputLabel>
                      <Select
                        name="tpdocument"
                        fullWidth
                        size="small"
                        required
                        disabled={infosSimulations.id ? true : false}
                        value={infosSimulations.tpdocument || ''}
                        onChange={(event) => HandleInputChange(event)}>
                        <MenuItem value={'CNPJ'}>Controle por CNPJ</MenuItem>
                        <MenuItem value={'CAEPF'}>Controle por CAEPF</MenuItem>
                      </Select>
                    </Grid>
                  )}
                  {infoState.id_profile !== 1 && (
                    <Grid item xs={12} md={2} sx={isMobile ? { mt: 0.5 } : { mt: 4.2 }}>
                      <TextField
                        name="nomevend"
                        fullWidth
                        label="Vendedor(a)"
                        variant="outlined"
                        size="small"
                        required
                        disabled
                        value={infosSimulations.labelvend || infoState.nome}
                      />
                    </Grid>
                  )}
                  {infoState.id_profile === 1 && infosSimulations.id && (
                    <>
                      <Grid item xs={12} md={2} sx={isMobile ? { mt: 0.5 } : { mt: 4.2 }}>
                        <TextField
                          name="nomevend"
                          fullWidth
                          label="Corretora"
                          variant="outlined"
                          size="small"
                          required
                          disabled
                          value={infosSimulations.empresa_vendedor}
                        />
                      </Grid>
                      <Grid item xs={12} md={2} sx={isMobile ? { mt: 0.5 } : { mt: 4.2 }}>
                        <Autocomplete
                          options={infoSelectVend}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          isOptionEqualToValue={(option, value) =>
                            value ? option.label === value : option.label === ''
                          }
                          onChange={(event, value) => HandleInputChange(value, { vend: true })}
                          value={infosSimulations.labelvend || ''}
                          defaultValue=""
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Vendedor(a)"
                              name="nomevend"
                              fullWidth
                              size="small"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={2} sx={isMobile ? { mt: 0.5 } : { mt: 2 }}>
                    <InputLabel id="label" sx={{ fontSize: '13px' }}>
                      Status da simulação *
                    </InputLabel>
                    <Select
                      name="status"
                      fullWidth
                      size="small"
                      required
                      value={infosSimulations.status || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={'Improvável'}>Improvável</MenuItem>
                      <MenuItem value={'Pouco provável'}>Pouco provável</MenuItem>
                      <MenuItem value={'Provável'}>Provável</MenuItem>
                      <MenuItem value={'Fechada'}>Fechada</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                {infosSimulations.id_type_client === 1 && (
                  <>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          name="nome"
                          fullWidth
                          label="Nome completo"
                          variant="outlined"
                          size="small"
                          required
                          value={infosSimulations.nome || ''}
                          onChange={(event) => HandleInputChange(event)}
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          name="nomesocial"
                          fullWidth
                          label="Nome social"
                          variant="outlined"
                          size="small"
                          value={infosSimulations.nomesocial || ''}
                          onChange={(event) => HandleInputChange(event)}
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <InputMask
                          mask="(99)99999-9999"
                          value={infosSimulations.celular || ''}
                          onChange={(event) => HandleInputChange(event)}>
                          {() => (
                            <TextField
                              name="celular"
                              fullWidth
                              label="Celular"
                              variant="outlined"
                              size="small"
                              required
                              //inputProps={{ maxLength: 14 }}
                            />
                          )}
                        </InputMask>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid item xs={12} md={4}>
                        <InputMask
                          mask="(99)9999-9999"
                          onChange={(event) => HandleInputChange(event)}
                          value={infosSimulations.telefone || ''}>
                          {() => (
                            <TextField
                              name="telefone"
                              fullWidth
                              label="Telefone residencial"
                              variant="outlined"
                              size="small"
                              //inputProps={{ maxLength: 13 }}
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          name="email"
                          fullWidth
                          label="Email"
                          required
                          size="small"
                          type="email"
                          variant="outlined"
                          value={infosSimulations.email || ''}
                          onChange={(event) => HandleInputChange(event)}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          name="dtnascimento"
                          label="Data de nascimento"
                          fullWidth
                          type="date"
                          required
                          variant="outlined"
                          size="small"
                          value={infosSimulations.dtnascimento || ''}
                          onChange={(event) => HandleInputChange(event)}
                          onBlur={() => validDtnascimento()}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid item xs={12} md={4}>
                        <InputMask
                          mask="999.999.999-99"
                          value={infosSimulations.cpf_cnpj || ''}
                          onChange={(event) => HandleInputChange(event)}
                          onBlur={(event) => validCPF(event)}>
                          {() => (
                            <TextField
                              name="cpf_cnpj"
                              fullWidth
                              label="CPF"
                              required
                              variant="outlined"
                              size="small"
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          name="rg"
                          label="RG"
                          fullWidth
                          required
                          variant="outlined"
                          size="small"
                          value={infosSimulations.rg || ''}
                          inputProps={{ maxLength: 20 }}
                          onChange={(event) => HandleInputChange(event)}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          options={infosOrgaEmissor}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          isOptionEqualToValue={(option, value) =>
                            value ? option.label === value : option.label === ''
                          }
                          onChange={(event, value) =>
                            HandleInputChange(value, { autocomplete: true })
                          }
                          value={infosSimulations.label || ''}
                          defaultValue=""
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Orgão emissor"
                              name="orgao_emissor"
                              fullWidth
                              size="small"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                {infosSimulations.id_type_client === 2 && (
                  <>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          name="nome"
                          fullWidth
                          label="Razão social"
                          variant="outlined"
                          size="small"
                          required
                          value={infosSimulations.nome || ''}
                          onChange={(event) => HandleInputChange(event)}
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          name="nomefantasia"
                          fullWidth
                          label="Nome fantasia"
                          variant="outlined"
                          size="small"
                          value={infosSimulations.nomefantasia || ''}
                          onChange={(event) => HandleInputChange(event)}
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      {infoState.controlacaepf === 'Sim' &&
                        infosSimulations.id_type_client === 2 &&
                        (infosSimulations.tpdocument === 'CNPJ' ||
                          !infosSimulations.tpdocument) && (
                          <Grid item xs={12} md={infoState.id_type_user === 1 ? 2 : 3}>
                            <InputMask
                              mask="99.999.999/0009-99"
                              value={infosSimulations.cpf_cnpj || ''}
                              onChange={(event) => HandleInputChange(event)}
                              onBlur={(event) => validCNPJ(event)}>
                              {() => (
                                <TextField
                                  name="cpf_cnpj"
                                  fullWidth
                                  label="CNPJ"
                                  required
                                  variant="outlined"
                                  size="small"
                                />
                              )}
                            </InputMask>
                          </Grid>
                        )}
                      {infoState.controlacaepf === 'Sim' &&
                        infosSimulations.id_type_client === 2 &&
                        infosSimulations.tpdocument === 'CAEPF' && (
                          <Grid item xs={12} md={infoState.id_type_user === 1 ? 2 : 3}>
                            <TextField
                              name="cpf_cnpj"
                              fullWidth
                              label="CAEPF"
                              variant="outlined"
                              size="small"
                              required
                              value={infosSimulations.cpf_cnpj || ''}
                              inputProps={{ maxLength: 100 }}
                              onChange={(event) => HandleInputChange(event)}
                            />
                          </Grid>
                        )}
                      {infoState.controlacaepf === 'Não' && infosSimulations.id_type_client === 2 && (
                        <Grid item xs={12} md={infoState.id_type_user === 1 ? 2 : 3}>
                          <InputMask
                            mask="99.999.999/0009-99"
                            value={infosSimulations.cpf_cnpj || ''}
                            onChange={(event) => HandleInputChange(event)}
                            onBlur={(event) => validCNPJ(event)}>
                            {() => (
                              <TextField
                                name="cpf_cnpj"
                                fullWidth
                                label="CNPJ"
                                required
                                variant="outlined"
                                size="small"
                              />
                            )}
                          </InputMask>
                        </Grid>
                      )}
                      {/* <Grid item xs={12} md={4}>
                        <InputMask
                          mask="99.999.999/0009-99"
                          value={infosSimulations.cpf_cnpj || ''}
                          onChange={(event) => HandleInputChange(event)}
                          onBlur={(event) => validCNPJ(event)}>
                          {() => (
                            <TextField
                              name="cpf_cnpj"
                              fullWidth
                              label="CNPJ"
                              required
                              variant="outlined"
                              size="small"
                            />
                          )}
                        </InputMask>
                      </Grid> */}
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          name="email"
                          fullWidth
                          label="Email"
                          required
                          size="small"
                          variant="outlined"
                          type="email"
                          value={infosSimulations.email || ''}
                          onChange={(event) => HandleInputChange(event)}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputMask
                          mask="(99)99999-9999"
                          value={infosSimulations.celular || ''}
                          onChange={(event) => HandleInputChange(event)}>
                          {() => (
                            <TextField
                              name="celular"
                              fullWidth
                              label="Celular"
                              variant="outlined"
                              size="small"
                              required
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputMask
                          mask="(99)9999-9999"
                          onChange={(event) => HandleInputChange(event)}
                          value={infosSimulations.telefone || ''}>
                          {() => (
                            <TextField
                              name="telefone"
                              fullWidth
                              label="Telefone comercial"
                              variant="outlined"
                              size="small"
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          name="ramal"
                          fullWidth
                          label="Ramal"
                          size="small"
                          variant="outlined"
                          value={infosSimulations.ramal || ''}
                          onChange={(event) => HandleInputChange(event)}
                          inputProps={{ maxLength: 2 }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container spacing={2} sx={{ mt: 0.1 }}>
                  {infosSimulations.tpcontrat_client === '3-COLETIVO POR ADESAO' && (
                    <Grid item xs={12} md={2}>
                      <InputLabel id="label" sx={{ fontSize: '13px' }}>
                        Associação para vinculação *
                      </InputLabel>
                      <Autocomplete
                        options={infosAssociations}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        isOptionEqualToValue={(option, value) =>
                          value ? option.label === value : option.label === ''
                        }
                        onChange={(event, value) => HandleInputChange(value, { association: true })}
                        value={infosSimulations.labelassociation || ''}
                        defaultValue=""
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="id_association"
                            fullWidth
                            size="small"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={4}>
                    <InputLabel id="label" sx={{ fontSize: '13px' }}>
                      Produto/Plano *
                    </InputLabel>
                    <Autocomplete
                      options={infosPlans}
                      renderOption={(props, option) => <li {...props}>{option.label}</li>}
                      isOptionEqualToValue={(option, value) =>
                        value ? option.label === value : option.label === ''
                      }
                      onChange={(event, value) => HandleInputChange(value, { plan: true })}
                      value={infosSimulations.labelplans || ''}
                      defaultValue=""
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="codplano"
                          fullWidth
                          size="small"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel id="label" sx={{ fontSize: '13px' }}>
                      Tabela *
                    </InputLabel>
                    <Autocomplete
                      options={infosTablesTemp}
                      renderOption={(props, option) => <li {...props}>{option.label}</li>}
                      isOptionEqualToValue={(option, value) =>
                        value ? option.label === value : option.label === ''
                      }
                      onChange={(event, value) => HandleInputChange(value, { table: true })}
                      value={infosSimulations.labeltables || ''}
                      defaultValue=""
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="codtab"
                          fullWidth
                          size="small"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </Grid>

                  {infosSimulations.id && (
                    <Grid item xs={12} md={2} mt={2}>
                      <TextField
                        name="valor_total"
                        fullWidth
                        label="Valor total"
                        disabled
                        variant="outlined"
                        size="small"
                        value={`R$ ${
                          // eslint-disable-next-line prettier/prettier
                        (infosSimulations.valueTotal !== 'NaN' &&
                          // eslint-disable-next-line prettier/prettier
                        infosSimulations.valueTotal !== 'undefined')
                            ? infosSimulations.valueTotal
                            : '0.00'
                        }`}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200' }}>
                {infosSimulations.id && (
                  <CustomGrid sx={{ mt: 1 }}>
                    {isMobile && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="right"
                        sx={{ mb: 1 }}>
                        <Button
                          variant="contained"
                          onClick={addRangeSimulations}
                          startIcon={<Iconify icon="eva:plus-fill" />}>
                          Nova
                        </Button>
                      </Stack>
                    )}
                    {!isMobile && (
                      <Stack direction="row" alignItems="center" justifyContent="start">
                        <Grid container>
                          <Grid item xs={12}>
                            <CustomInfosForm>
                              Gerenciamento de vidas: adicionar e/ou remover faixas etárias
                            </CustomInfosForm>
                          </Grid>
                        </Grid>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ mb: 1 }}>
                          <Button
                            variant="contained"
                            onClick={addRangeSimulations}
                            startIcon={<Iconify icon="eva:plus-fill" />}>
                            Nova
                          </Button>
                          {!isMobile && (
                            <Button
                              variant="outlined"
                              onClick={getValues}
                              sx={{ ml: 1 }}
                              startIcon={<Iconify icon="eva:refresh-outline" />}>
                              Atualizar
                            </Button>
                          )}
                        </Stack>
                      </Stack>
                    )}
                    <DataGrid
                      dataSource={values}
                      showBorders={true}
                      remoteOperations={true}
                      wordWrapEnabled={true}
                      allowColumnResizing={true}
                      columnAutoWidth={true}>
                      <Column dataField="faixa_etaria" caption="Faixa etária" />
                      <Column dataField="tpbenef" caption="Tipo de beneficiário" />
                      <Column dataField="qtdevidas" caption="Quantidade de vidas" />
                      <Column dataField="valsimulation" caption="Valor R$" />
                      <Column
                        dataField="Ações"
                        allowSorting={false}
                        cellRender={(data) => <ActionsButtons data={data} />}
                      />
                      <SearchPanel visible={true} width={240} placeholder="Procurar..." />
                      {!isMobile && (
                        <GroupPanel
                          visible={true}
                          emptyPanelText="Arraste as colunas aqui para agrupar"
                        />
                      )}
                      <Scrolling rowRenderingMode="virtual"></Scrolling>
                      <Paging defaultPageSize={10} />
                      <Pager
                        visible={true}
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                      />
                      <ColumnChooser
                        enabled={true}
                        title={'Escolher uma coluna'}
                        emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                      />
                      <ColumnFixing enabled={true} />
                      <HeaderFilter visible={true} allowSearch={true} />
                    </DataGrid>
                  </CustomGrid>
                )}
              </Box>

              <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="success"
                  startIcon={<Iconify icon="eva:save-fill" />}
                  sx={{ mr: 2 }}>
                  {infosSimulations.id ? 'Atualizar' : 'Salvar'}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to="/app/simulations"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </Stack>
            </form>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default AddEditSimulations;
