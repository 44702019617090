import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { CustomGrid } from '../../../../assets/style/globalStyle';
import { selectLogin } from '../../../../store/login/loginSlice';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import {
  Stack,
  Typography,
  Button,
  Card,
  Grid,
  Radio,
  FormControlLabel,
  FormControl
} from '@mui/material';

import MoreMenu from '../../../components/multiplesComponents/MoreMenu';
import Iconify from '../../../../@core/theme/Iconify';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import RenderPage from '../../../layouts/RenderPage';
import configAxios from '../../../../services/configAxios';

import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

const allowedPageSizes = [5, 10, 20, 30];
const Users = (props) => {
  const infoStateTemp = useSelector(selectLogin);
  const [infosUsers, setInfosUsers] = useState({});
  const [infosFilter, setInfosFilter] = useState({});
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  useEffect(() => {
    if (infoState.id_type_user === 2) {
      getInfosUsers();
    }
  }, []);

  function HandleChange(event) {
    setInfosFilter({ [event.target.name]: event.target.value });
  }

  function getInfosUsers() {
    let router;
    if (infoState.id_type_user === 2) {
      router = `/cadusers/listUsers/corretora/${infoState.id_broker}`;
      configAxios
        .get(router)
        .then((response) => {
          setInfosUsers(response.data);
        })
        .catch(() => {
          NotificationManager.warning(
            'Tivemos dificuldades para buscar os usuários, por favor, tente novamente!',
            'Atenção'
          );
        });
      return;
    } else {
      if (infosFilter.filterUser) {
        const router =
          infosFilter.filterUser === 'corretora'
            ? `/cadusers/listUsers/corretora`
            : `/cadusers/listUsers/operadora`;
        configAxios
          .get(router)
          .then((response) => {
            setInfosUsers(response.data);
          })
          .catch(() => {
            NotificationManager.warning(
              'Tivemos dificuldades para buscar os usuários, por favor, tente novamente!',
              'Atenção'
            );
          });

        return;
      }
      NotificationManager.warning('Por favor, selecione um item no filtro de usuários!', 'Atenção');
      return;
    }
  }

  function ActionsButtons(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          router: 'editUsers',
          functionGrid: getInfosUsers,
          otherInfos: item
        }}
      />
    ));
  }

  function ActiveValid(infos) {
    if (infos.data.data.ativo === true) {
      return 'Ativo';
    }
    return 'Inativo';
  }

  function Profile(infos) {
    if (infos.data.data.descprofile) {
      return infos.data.data.descprofile;
    }
    return ' - ';
  }

  function User(infos) {
    if (infos.data.data.nomefantasia) {
      return infos.data.data.nomefantasia;
    }
    return ' - ';
  }

  return (
    <RenderPage title="Usuários">
      <>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Lista de usuários
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para visualizar os usuários
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
            <Button
              variant="contained"
              component={RouterLink}
              to="/app/addUsers"
              startIcon={<Iconify icon="eva:plus-fill" />}>
              Novo
            </Button>
            {!isMobile && (
              <Button
                variant="outlined"
                onClick={getInfosUsers}
                sx={{ ml: 1 }}
                startIcon={<Iconify icon="eva:refresh-outline" />}>
                Atualizar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <Stack direction="row" alignItems="center" justifyContent="start">
              {infoState.id_type_user !== 2 && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Filtrar usuários
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset" variant="standard">
                      <FormControlLabel
                        control={
                          <Radio
                            checked={infosFilter.filterUser === 'operadora'}
                            onChange={HandleChange}
                            value="operadora"
                            name="filterUser"
                          />
                        }
                        label="Usuários da administradora/operadora"
                      />
                    </FormControl>
                    <FormControl component="fieldset" variant="standard">
                      <FormControlLabel
                        control={
                          <Radio
                            checked={infosFilter.filterUser === 'corretora'}
                            onChange={HandleChange}
                            value="corretora"
                            name="filterUser"
                          />
                        }
                        label="Usuários dos prestadores/corretoras"
                      />
                    </FormControl>
                    {isMobile && (
                      <Stack direction="row" alignItems="center" justifyContent="end">
                        <Button
                          variant="contained"
                          onClick={getInfosUsers}
                          sx={{ mr: 1 }}
                          startIcon={<Iconify icon="eva:funnel-fill" />}>
                          Filtrar
                        </Button>
                      </Stack>
                    )}
                    {!isMobile && (
                      <Button
                        variant="contained"
                        onClick={getInfosUsers}
                        sx={{ mr: 1 }}
                        startIcon={<Iconify icon="eva:funnel-fill" />}>
                        Filtrar
                      </Button>
                    )}
                  </Grid>
                </Grid>
              )}
            </Stack>
          </CustomGrid>
          <CustomGrid>
            <DataGrid
              dataSource={infosUsers}
              showBorders={true}
              remoteOperations={true}
              wordWrapEnabled={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              noDataText={'Não foram encontrados usuários'}>
              <Column dataField="id" />
              <Column dataField="coduser" caption="Código do usuário" />
              <Column dataField="nome" caption="Nome" />
              <Column dataField="email" />
              <Column
                width={150}
                dataField="descperfil"
                caption="Perfil"
                cellRender={(data) => <Profile data={data} />}
              />
              {infoState.id_type_user === 1 && (
                <Column
                  dataField="nomefantasia"
                  caption="Corretora/Prestador"
                  cellRender={(data) => <User data={data} />}
                />
              )}
              <Column
                dataField="ativo"
                caption="Status"
                cellRender={(data) => <ActiveValid data={data} />}
              />
              <Column
                dataField="Ações"
                width={100}
                allowSorting={false}
                cellRender={(data) => <ActionsButtons data={data} />}
              />
              <SearchPanel visible={true} width={240} placeholder="Procurar..." />
              {!isMobile && (
                <GroupPanel visible={true} emptyPanelText="Arraste as colunas aqui para agrupar" />
              )}
              <Scrolling rowRenderingMode="virtual"></Scrolling>
              <Paging defaultPageSize={10} />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              <ColumnChooser
                enabled={true}
                title={'Escolher uma coluna'}
                emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
              />
              <ColumnFixing enabled={true} />
              <HeaderFilter visible={true} allowSearch={true} />
            </DataGrid>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default Users;
