import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
  CustomGrid,
  CustomInfosForm,
  TextareaAutosize
} from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import {
  Stack,
  Typography,
  Card,
  Button,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Box
} from '@mui/material';

import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import configAxios from '../../../../services/configAxios';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';

const addEditProducts = (props) => {
  const navigation = useNavigate();
  const location = useLocation();
  const { state, pathname } = location;
  const pathNameSplice = pathname.split('/');
  const [infosWarning, setInfosWarning] = useState(state ? { ...state.infosEdit } : {});

  useEffect(() => {
    if (state === null && pathNameSplice[2] === 'editWarning') [navigation('/app/warnings')];
  }, []);

  function HandleInputChange(event) {
    setInfosWarning({ ...infosWarning, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    const router = infosWarning.id ? '/warnings/updateWarning' : '/warnings/addWarning';
    configAxios
      .post(router, infosWarning)
      .then((response) => {
        const message = infosWarning.id
          ? 'Aviso atualizado com sucesso!'
          : 'Aviso inserido com sucesso!';

        NotificationManager.success(message, 'Sucesso');

        setInfosWarning({
          ...infosWarning,
          id: response.data.id
        });

        const nav = infosWarning.id
          ? `/app/editWarning/${infosWarning.id}`
          : `/app/editWarning/${response.data.id}`;

        navigation(nav, {
          state: {
            infosEdit: {
              ...infosWarning,
              id: infosWarning.id ? infosWarning.id : response.data.id
            }
          }
        });
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }
  return (
    <RenderPage title="Avisos" id="warnings">
      <>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de avisos
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para inserir e editar os avisos
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/warnings"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <form onSubmit={HandleSubmit}>
              <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 2 }}>
                <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {infosWarning.id && (
                    <Grid item xs={3} md={1}>
                      <TextField
                        name="idproduct"
                        fullWidth
                        label="ID"
                        variant="outlined"
                        size="small"
                        required
                        value={infosWarning.id || ''}
                        disabled
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={2.5}>
                    <TextField
                      name="initial_date"
                      fullWidth
                      label="Data inicial"
                      variant="outlined"
                      size="small"
                      type="date"
                      required
                      value={infosWarning.initial_date || ''}
                      onChange={(event) => HandleInputChange(event)}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2.5}>
                    <TextField
                      name="end_date"
                      fullWidth
                      label="Data final"
                      variant="outlined"
                      size="small"
                      type="date"
                      required
                      value={infosWarning.end_date || ''}
                      onChange={(event) => HandleInputChange(event)}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { mt: 0 } : { fontSize: '13px', mt: -2.2 }}>
                      Direção *
                    </InputLabel>
                    <Select
                      name="direction"
                      fullWidth
                      size="small"
                      required
                      value={infosWarning.direction || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={'Operadora'}>Operadora</MenuItem>
                      <MenuItem value={'Corretora'}>Corretora</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { mt: 0 } : { fontSize: '13px', mt: -2.2 }}>
                      Cor do fundo *
                    </InputLabel>
                    <Select
                      name="severity"
                      fullWidth
                      size="small"
                      required
                      value={infosWarning.severity || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={'info'}>Azul</MenuItem>
                      <MenuItem value={'warning'}>Amarelo</MenuItem>
                      <MenuItem value={'error'}>Vermelho</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <InputLabel id="label" sx={{ fontSize: '13px', ml: 0.3 }}>
                      Descreva o aviso *
                    </InputLabel>
                    <TextareaAutosize
                      name="warning"
                      required
                      label="Descreva o aviso"
                      size="small"
                      variant="outlined"
                      minRows={3}
                      value={infosWarning.warning || ''}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="success"
                  sx={{ mr: 2 }}
                  startIcon={<Iconify icon="eva:save-fill" />}>
                  {infosWarning.id ? 'Atualizar' : 'Salvar'}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to="/app/warnings"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </Stack>
            </form>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default addEditProducts;
