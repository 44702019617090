import React, { useState, useEffect } from 'react';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { Link as RouterLink } from 'react-router-dom';
import { Stack, Typography, Button, Card, Grid, TextField } from '@mui/material';
import { formatDate, formatCnpjCpf } from '../../../../@core/utils/utilsFunctions';
import { selectLogin, selectClient } from '../../../../store/login/loginSlice';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { NotificationManager } from 'react-notifications';
import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

import configAxios from '../../../../services/configAxios';
import MoreMenu from '../../../components/multiplesComponents/MoreMenu';
import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import ReactShortcut from 'react-shortcut';

import FacialRecognition from './FacialRecognition';
import SendWhatsApp from './SendWhatsApp';

const allowedPageSizes = [5, 10, 20, 30];

const clients = (props) => {
  const [infosClients, setInfosClients] = useState({});
  const infoStateTemp = useSelector(selectLogin);
  const company = useSelector(selectClient);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });
  const [infosFilter, setInfosFilter] = useState([]);
  const [facialRecog, setFacialRecog] = useState(false);
  const [openModalFacilRecog, setOpenModalRecog] = useState(false);
  const [whatsApp, setWhatsApp] = useState(false);
  const [openModalWhatsApp, setOpenModalWhatsApp] = useState(false);

  useEffect(() => {
    getInfosClients();
  }, []);

  function getInfosClients() {
    /***
     * tables tb_profiles & tb_type_users
     * profiles 1 = admin, 2 = usuario, 3 = vendedor
     * typeusers 1 = operadora 2 = corretora
     */
    let router;
    if (infoState.id_type_user !== 2) {
      router = `/clients/infosClients/?dateinitial=${infosFilter.dateinitial}&dateend=${infosFilter.dateend}`;
    } else if (
      infoState.id_type_user === 2 &&
      infoState.id_profile !== 1 &&
      infoState.id_profile !== 2
    ) {
      router = `/clients/infosClients/${infoState.id_broker}/${infoState.id}/?dateinitial=${infosFilter.dateinitial}&dateend=${infosFilter.dateend}`;
    } else {
      router = `/clients/infosClients/${infoState.id_broker}/?dateinitial=${infosFilter.dateinitial}&dateend=${infosFilter.dateend}`;
    }
    configAxios
      .get(router)
      .then((response) => {
        setInfosClients(response.data);
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para buscar os clientes, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleInputsFilters(event) {
    setInfosFilter({ ...infosFilter, [event.target.name]: event.target.value });
  }

  function ActionsButtons(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          router: 'editClients',
          deleteRouter: 'clients/deleteClient',
          deleteItem: 'a venda selecionada',
          functionGrid: getInfosClients,
          otherInfos: item,
          statusvenda: item.statusvenda
        }}
      />
    ));
  }

  function FormatDate(data) {
    if (data.data) {
      let dateFormated = formatDate(data.data);
      return dateFormated;
    } else {
      return '-';
    }
  }

  function FormatCPFCNPJ(infos) {
    let cpfCnpjFormated = formatCnpjCpf(infos.data.data.cpf_cnpj);
    return cpfCnpjFormated;
  }

  function VerifyStatus(infos) {
    if (infoState.id_type_user === 2 && infos.data.data.statusvenda === 'Negada') {
      return '-';
    }
    return infos.data.data.statusvenda;
  }

  function Facialrecognition() {
    setFacialRecog(!facialRecog);
  }

  function whatsAppIntegration() {
    setWhatsApp(!whatsApp);
  }

  return (
    <RenderPage title="Vendas">
      <>
        <ReactShortcut keys="ctrl+B" onKeysPressed={Facialrecognition} />
        <ReactShortcut keys="ctrl+Q" onKeysPressed={whatsAppIntegration} />

        <FacialRecognition openModal={openModalFacilRecog} setOpenModal={setOpenModalRecog} />
        {company === 'homolog' && (
          <SendWhatsApp openModal={openModalWhatsApp} setOpenModal={setOpenModalWhatsApp} />
        )}

        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Lista de vendas
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para visualizar as vendas
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
            {infoState.id_type_user === 2 && infoState.id_profile === 3 && (
              <Button
                variant="contained"
                component={RouterLink}
                to="/app/addClients"
                startIcon={<Iconify icon="eva:plus-fill" />}>
                Nova
              </Button>
            )}
            {!isMobile && (
              <>
                {whatsApp && (
                  <Button
                    variant="outlined"
                    onClick={() => setOpenModalWhatsApp(true)}
                    sx={{ ml: 1 }}
                    startIcon={<Iconify icon="eva:navigation-2-outline" />}>
                    WhatsApp
                  </Button>
                )}
                {facialRecog && (
                  <Button
                    variant="outlined"
                    onClick={() => setOpenModalRecog(true)}
                    sx={{ ml: 1 }}
                    startIcon={<Iconify icon="eva:smiling-face-outline" />}>
                    Facial
                  </Button>
                )}
                <Button
                  variant="outlined"
                  onClick={getInfosClients}
                  sx={{ ml: 1 }}
                  startIcon={<Iconify icon="eva:refresh-outline" />}>
                  Atualizar
                </Button>
              </>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <CustomInfosForm>
              * Sem filtros, a busca é limitada aos últimos 200 registros
            </CustomInfosForm>
            <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
              <Grid item xs={12} md={3}>
                <TextField
                  name="dateinitial"
                  fullWidth
                  label="Data inicial"
                  variant="outlined"
                  size="small"
                  type="date"
                  // required
                  value={infosFilter.dateinitial || ''}
                  onChange={(event) => HandleInputsFilters(event)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  name="dateend"
                  fullWidth
                  label="Data final"
                  variant="outlined"
                  size="small"
                  type="date"
                  // required
                  value={infosFilter.dateend || ''}
                  onChange={(event) => HandleInputsFilters(event)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              {!isMobile && (
                <Grid item xs={12} md={2}>
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() => getInfosClients()}
                    startIcon={<Iconify icon="eva:funnel-outline" />}
                    sx={{ mr: 2 }}>
                    Filtrar
                  </Button>
                </Grid>
              )}
              {isMobile && (
                <Grid item xs={12} md={2}>
                  <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mb: 1 }}>
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      onClick={() => getInfosClients()}
                      startIcon={<Iconify icon="eva:funnel-outline" />}
                      sx={{ mr: 2 }}>
                      Filtrar
                    </Button>
                  </Stack>
                </Grid>
              )}
            </Grid>
            <DataGrid
              dataSource={infosClients}
              showBorders={true}
              remoteOperations={true}
              wordWrapEnabled={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              allowExportSelectedData={true}
              noDataText={'Não foram encontrados registros'}>
              <Column dataField="id" />
              <Column dataField="codclient" caption="Cód. cliente" />
              {/* <Column
                dataField="codsimulation"
                caption="Cód. simulação"
                cellRender={(data) => <Simulation data={data} />}
              /> */}
              <Column dataField="nome" caption="Nome/razão social" />
              <Column
                width={150}
                dataField="cpf_cnpj"
                cellRender={(data) => <FormatCPFCNPJ data={data} />}
                caption="Cpf/Cnpj"
              />
              <Column dataField="tpcontrat" caption="Tipo de contratação" width={220} />
              <Column
                dataField="statusvenda"
                caption="Status da venda"
                width={180}
                cellRender={(data) => <VerifyStatus data={data} />}
              />
              {infoState.id_type_user !== 2 && (
                <Column dataField="empresa_vendedor" caption="Corretora" />
              )}
              <Column dataField="nomevend" caption="Vendedor(a)" />
              <Column
                dataField="datacadastro"
                caption="Dt. cadastro"
                cellRender={(data) => <FormatDate data={data.data.datacadastro} />}
              />
              <Column
                dataField="dtvigencia"
                caption="Dt. vigência"
                cellRender={(data) => <FormatDate data={data.data.dtvigencia} />}
              />
              <Column
                dataField="Ações"
                allowExporting={false}
                allowSorting={false}
                cellRender={(data) => <ActionsButtons data={data} />}
              />
              {/* <StateStoring enabled={true} type="localStorage" storageKey="storage" /> */}
              <SearchPanel visible={true} width={240} placeholder="Procurar..." />
              {!isMobile && (
                <GroupPanel visible={true} emptyPanelText="Arraste as colunas aqui para agrupar" />
              )}
              <Scrolling rowRenderingMode="virtual"></Scrolling>
              <Paging defaultPageSize={10} />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              <ColumnChooser
                enabled={true}
                title={'Escolher uma coluna'}
                emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
              />
              <ColumnFixing enabled={true} />
              <HeaderFilter visible={true} allowSearch={true} />
            </DataGrid>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default clients;
