import React, { useState, useEffect } from 'react';
import { selectLogin } from '../../../../store/login/loginSlice';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import {
  Stack,
  Typography,
  Button,
  Card,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Box,
  Menu,
  Fade
} from '@mui/material';
import { isMobile } from 'react-device-detect';

import configAxios from '../../../../services/configAxios';
import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

/***
 * tables tb_profiles & tb_type_users
 * profiles 1 = admin, 2 = usuario, 3 = vendedor
 * typeusers 1 = operadora 2 = corretora
 */

const RepSimulations = (props) => {
  const [infosReport, setInfosReport] = useState([]);
  const [infosBrokers, setInfosBroker] = useState([]);
  const [infosSelectVend, setInfosSelectVend] = useState([]);
  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (infoState.id_type_user === 2 && infoState.id_profile !== 3) {
      getInfoSelectVend();
    }
    if (infoState.id_type_user === 2 && infoState.id_profile === 3) {
      setInfosReport({
        ...infosReport,
        id_broker: infoState.id_broker,
        id_vend: infoState.id,
        brokerSelected: infoState.name_broker,
        infosSelectVend: `${infoState.id} - ${infoState.nome}`
      });
    }
    if (infoState.id_type_user === 1) {
      getBrokers();
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function getBrokers() {
    configAxios
      .post('/brokers/listBrokerLabelUsers')
      .then((response) => {
        setInfosBroker(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os corretores, tente novamente!',
          'Atenção'
        );
      });
  }

  function getInfoSelectVend(id_broker) {
    let idGetVend = id_broker ? id_broker : infoState.id_broker;
    configAxios
      .get(`/brokers/listVendLabel/${idGetVend}`)
      .then((response) => {
        setInfosSelectVend(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar a lista de vendedores! Tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleInputChange(event, broker, vend) {
    if (broker) {
      infosReport.infosSelectVend = '';
      if (event) {
        setInfosReport({
          ...infosReport,
          brokerSelected: event.label,
          id_broker: event.id,
          id_vend: null
        });
        getInfoSelectVend(event.id);
      } else {
        setInfosReport({
          ...infosReport,
          brokerSelected: null,
          id_broker: null,
          infosSelectVend: null,
          id_vend: null
        });
        setInfosSelectVend([]);
      }
      return;
    }
    if (vend) {
      if (event) {
        setInfosReport({ ...infosReport, infosSelectVend: event.label, id_vend: event.id });
      } else {
        setInfosReport({ ...infosReport, infosSelectVend: null, id_vend: null });
      }
      return;
    }

    setInfosReport({ ...infosReport, [event.target.name]: event.target.value });
  }

  function HandleExport(formatFile) {
    setAnchorEl(null);

    if (
      !infosReport.datainicial ||
      !infosReport.datafim ||
      !infosReport.tiporelatorio ||
      !infosReport.status ||
      !infosReport.tipocontratacao
    ) {
      NotificationManager.warning('Por favor, preencha todos os campos obrigatórios!', 'Atenção');
      return;
    }
    const infosExport = {
      infosReport: infosReport,
      tipoReport: formatFile,
      id_type_user: infoState.id_type_user
    };

    configAxios
      .post('/reports/repSimulations', infosExport, { responseType: 'blob' })
      .then((response) => {
        if (response.status === 202) {
          NotificationManager.warning(
            'Não encontramos informações para os parâmetros selecionados!',
            'Atenção'
          );
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        if (formatFile === 'pdf') {
          link.setAttribute('download', 'Relatório de simulações.pdf');
        } else {
          link.setAttribute('download', 'Relatório de simulações.xlsx');
        }
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para gerar o relatório! Por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  function ClearInputs() {
    setInfosReport([]);
  }

  return (
    <RenderPage title="Relatórios">
      <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
        <Grid container>
          <BreadcrumbsNav {...props} />
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Typography variant="h4" gutterBottom>
              Relatório de simulações
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Utilize as opções abaixo para filtrar o relatório de simulações
            </Typography>
          </Grid>
        </Grid>
        <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
          {!isMobile && (
            <>
              <Button
                variant="outlined"
                onClick={ClearInputs}
                sx={{ ml: 1, mr: 1 }}
                startIcon={<Iconify icon="eva:refresh-outline" />}>
                Limpar
              </Button>
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/dashboard"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            </>
          )}
        </Stack>
      </Stack>
      <Card>
        <CustomGrid>
          <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 2 }}>
            <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} md={3}>
                <TextField
                  name="datainicial"
                  fullWidth
                  label="Data inicial"
                  variant="outlined"
                  required
                  size="small"
                  type="date"
                  value={infosReport.datainicial || ''}
                  onChange={(event) => HandleInputChange(event)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  name="datafim"
                  fullWidth
                  label="Data final"
                  variant="outlined"
                  required
                  size="small"
                  type="date"
                  value={infosReport.datafim || ''}
                  onChange={(event) => HandleInputChange(event)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel
                  id="label"
                  sx={isMobile ? { fontSize: '13px', mt: 0 } : { fontSize: '13px', mt: -2.2 }}>
                  Tipo de relatório *
                </InputLabel>
                <Select
                  name="tiporelatorio"
                  fullWidth
                  size="small"
                  required
                  value={infosReport.tiporelatorio || ''}
                  onChange={(event) => HandleInputChange(event)}>
                  <MenuItem value={'Simplificado'}>Simplificado</MenuItem>
                  <MenuItem value={'Analítico'}>Analítico</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel
                  id="label"
                  sx={isMobile ? { fontSize: '13px', mt: 0 } : { fontSize: '13px', mt: -2.2 }}>
                  Status da simulação *
                </InputLabel>
                <Select
                  name="status"
                  fullWidth
                  size="small"
                  required
                  value={infosReport.status || ''}
                  onChange={(event) => HandleInputChange(event)}>
                  <MenuItem value={'Improvável'}>Improvável</MenuItem>
                  <MenuItem value={'Pouco provável'}>Pouco provável</MenuItem>
                  <MenuItem value={'Provável'}>Provável</MenuItem>
                  <MenuItem value={'Fechada'}>Fechada</MenuItem>
                  <MenuItem value={'Todos'}>Todos</MenuItem>
                </Select>
              </Grid>
            </Grid>
            {infoState.id_type_user === 2 && infoState.id_profile === 3 && (
              <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    id="label"
                    sx={isMobile ? { fontSize: '13px', mt: 0 } : { fontSize: '13px', mt: -2.2 }}>
                    Tipo de contratação *
                  </InputLabel>
                  <Select
                    name="tipocontratacao"
                    fullWidth
                    size="small"
                    required
                    value={infosReport.tipocontratacao || ''}
                    onChange={(event) => HandleInputChange(event)}>
                    <MenuItem value={'1-INDIVIDUAL/FAMILIAR'}>1-INDIVIDUAL/FAMILIAR</MenuItem>
                    <MenuItem value={'2-COLETIVO EMPRESARIAL'}>2-COLETIVO EMPRESARIAL</MenuItem>
                    <MenuItem value={'3-COLETIVO POR ADESÃO'}>3-COLETIVO POR ADESÃO</MenuItem>
                    <MenuItem value={'4-TODOS'}>4-TODOS</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            )}
            {infoState.id_type_user !== 2 && (
              <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    id="label"
                    sx={isMobile ? { fontSize: '13px', mt: 0 } : { fontSize: '13px', mt: -2.2 }}>
                    Tipo de contratação *
                  </InputLabel>
                  <Select
                    name="tipocontratacao"
                    fullWidth
                    size="small"
                    required
                    value={infosReport.tipocontratacao || ''}
                    onChange={(event) => HandleInputChange(event)}>
                    <MenuItem value={'1-INDIVIDUAL/FAMILIAR'}>1-INDIVIDUAL/FAMILIAR</MenuItem>
                    <MenuItem value={'2-COLETIVO EMPRESARIAL'}>2-COLETIVO EMPRESARIAL</MenuItem>
                    <MenuItem value={'3-COLETIVO POR ADESÃO'}>3-COLETIVO POR ADESÃO</MenuItem>
                    <MenuItem value={'4-TODOS'}>4-TODOS</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    options={infosBrokers}
                    renderOption={(props, option) => <li {...props}>{option.label}</li>}
                    isOptionEqualToValue={(option, value) =>
                      value ? option.label === value : option.label === ''
                    }
                    onChange={(event, value) => HandleInputChange(value, true)}
                    value={infosReport.brokerSelected || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione a corretora"
                        name="brokerSelected"
                        fullWidth
                        size="small"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    options={infosSelectVend}
                    renderOption={(props, option) => <li {...props}>{option.label}</li>}
                    isOptionEqualToValue={(option, value) =>
                      value ? option.label === value : option.label === ''
                    }
                    onChange={(event, value) => HandleInputChange(value, false, true)}
                    value={infosReport.infosSelectVend || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione o vendedor"
                        name="infosSelectVend"
                        fullWidth
                        size="small"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            {infoState.id_type_user === 2 &&
              (infoState.id_profile === 1 || infoState.id_profile === 2) && (
                <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                  <Grid item xs={12} md={4}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px', mt: 0 } : { fontSize: '13px', mt: -2.2 }}>
                      Tipo de contratação *
                    </InputLabel>
                    <Select
                      name="tipocontratacao"
                      fullWidth
                      size="small"
                      required
                      value={infosReport.tipocontratacao || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={'1-Individual/Familiar'}>1-INDIVIDUAL/FAMILIAR</MenuItem>
                      <MenuItem value={'2-Coletivo empresarial'}>2-COLETIVO EMPRESARIAL</MenuItem>
                      <MenuItem value={'3-Coletivo por adesão'}>3-COLETIVO POR ADESÃO</MenuItem>
                      <MenuItem value={'4-Todos'}>4-TODOS</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      options={infosSelectVend}
                      renderOption={(props, option) => <li {...props}>{option.label}</li>}
                      isOptionEqualToValue={(option, value) =>
                        value ? option.label === value : option.label === ''
                      }
                      onChange={(event, value) => HandleInputChange(value, false, true)}
                      value={infosReport.infosSelectVend || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecione o vendedor"
                          name="infosSelectVend"
                          fullWidth
                          size="small"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
          </Box>
          <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
            <div>
              <Button
                sx={{ mr: 1 }}
                variant="contained"
                color="success"
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                startIcon={<Iconify icon="eva:file-text-outline" />}
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleClick}>
                Exportar
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}>
                <MenuItem onClick={() => HandleExport('pdf')}>
                  <PictureAsPdfIcon sx={{ mr: 1 }} />
                  <CustomInfosForm sx={{ fontSize: '13px', mt: 1 }}>
                    Exportar em pdf
                  </CustomInfosForm>
                </MenuItem>
                <MenuItem onClick={() => HandleExport('xlsx')}>
                  <DocumentScannerIcon sx={{ mr: 1 }} />
                  <CustomInfosForm sx={{ fontSize: '13px', mt: 1 }}>
                    Exportar em xlsx
                  </CustomInfosForm>
                </MenuItem>
              </Menu>
            </div>
            <Button
              variant="contained"
              color="error"
              component={RouterLink}
              to="/app/dashboard"
              startIcon={<Iconify icon="eva:close-circle-fill" />}>
              Cancelar
            </Button>
          </Stack>
        </CustomGrid>
      </Card>
    </RenderPage>
  );
};

export default RepSimulations;
