import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

Logo.propTypes = {
  disabledLink: PropTypes.bool
};

export default function Logo({ disabledLink = false }) {
  const userAuth = useSelector((state) => state.login.authenticatedUser);
  const permissions = useSelector((state) => state.login.permissions);
  const img = userAuth.userLoggedIn[0].logo;
  const isLogoTemp = useMemo(() => img, []);
  const isLogo = <Box component="img" sx={{ ml: 3, width: '70%' }} src={`${isLogoTemp}`} />;
  const logo = isLogo;
  if (disabledLink) {
    return <>{logo}</>;
  }
  let router = permissions[0].id_type_user === 3 ? '/app/signature' : '/app/dashboard';
  return <RouterLink to={router}>{logo}</RouterLink>;
}
