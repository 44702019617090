/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { validateCPF } from '../../../../@core/utils/utilsFunctions';
import {
  login,
  client as clientDispatch,
  permissions,
  typeClient
} from '../../../../store/login/loginSlice';
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  FormControlLabel,
  Grid
} from '@mui/material';

import Iconify from '../../../../@core/theme/Iconify';
import configAxios from '../../../../services/configAxios';
import InputMask from 'react-input-mask';

const LoginForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [client, setClient] = useState('');

  const [infosLogin, setInfosLogin] = useState({
    company: '',
    email: '',
    password: '',
    type: 'operadora'
  });

  useEffect(() => {
    const urlTemp = window.location.pathname.split('/');
    if (urlTemp[1] !== 'login' && urlTemp[1] !== '' && urlTemp[1] !== 'portal') {
      setClient(urlTemp[1].toUpperCase());
      infosLogin.company = urlTemp[1].toUpperCase();
    } else if (urlTemp[1] !== 'login' && urlTemp[1] !== '') {
      setClient(urlTemp[2].toUpperCase());
      infosLogin.company = urlTemp[2].toUpperCase();
    }
  }, []);

  function HandleSubmit(event) {
    event.preventDefault();
    if (props.urlClient) {
      infosLogin.type = 'client';
    }
    configAxios
      .post('/auth/login', { ...infosLogin })
      .then((response) => {
        if (response) {
          if (response.status === 200) {
            dispatch(permissions(response.data.permissions));
            dispatch(login(response.data));
            dispatch(clientDispatch(infosLogin.company.toLowerCase()));
            if (props.urlClient) {
              dispatch(typeClient(infosLogin.typeClient));
            }
            NotificationManager.success(
              `Bem vindo(a), ${response.data.userLoggedIn[0].nome}, esteja pronto para explorar!`,
              'Sucesso'
            );
            if (infosLogin.type == 'client') {
              navigate('/app/signature');
            } else {
              navigate('/app/dashboard');
            }
          } else {
            NotificationManager.warning(
              'Usuário ou senha inválidos, por favor, verifique!',
              'Atenção'
            );
          }
        } else {
          NotificationManager.warning(
            'Tivemos dificuldades para atender a requisição no momento, tente novamente!',
            'Atenção'
          );
        }
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleInputChange(event) {
    if (event.target.name === 'password') {
      event.target.value = event.target.value.trim();
    }
    setInfosLogin({ ...infosLogin, [event.target.name]: event.target.value });
  }

  function validCPF(event) {
    if (event.target.value) {
      const cpfIsValid = validateCPF(event.target.value);
      if (!cpfIsValid) {
        NotificationManager.warning('CPF inválido, por favor verifique!', 'Atenção');
        setInfosLogin({ ...infosLogin, cpf: '' });
        return;
      }
      setInfosLogin({ ...infosLogin, [event.target.name]: event.target.value });
    }
  }
  return (
    <>
      {!props.urlClient && (
        <form onSubmit={HandleSubmit} className="formLogin">
          <Stack spacing={3}>
            {!client && (
              <TextField
                name="company"
                id="company"
                label="Operadora/Administradora"
                onChange={(event) => HandleInputChange(event)}
                value={infosLogin.company || client}
                color="primary"
                required
                inputProps={{ maxLength: 100 }}
              />
            )}
            <TextField
              name="email"
              id="email"
              label="Endereço de e-mail"
              onChange={(event) => HandleInputChange(event)}
              value={infosLogin.email}
              color="primary"
              type="email"
              required
              inputProps={{ maxLength: 50 }}
            />
            <FormControl fullWidth>
              <InputLabel id="selectLabel" color="mplan">
                Tipo *
              </InputLabel>
              <Select
                id="type"
                name="type"
                value={infosLogin.type || ''}
                label="type"
                color="primary"
                required
                onChange={(event) => HandleInputChange(event)}>
                <MenuItem value="operadora" id="operadora">
                  Operadora/Administradora
                </MenuItem>
                <MenuItem value="corretora" id="corretora">
                  Corretora/Vendedor(a)
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="password"
              name="password"
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              onChange={(event) => HandleInputChange(event)}
              value={infosLogin.password}
              inputProps={{ maxLength: 50 }}
              color="primary"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Stack>
          <Box mt={3}>
            <Button
              id="login"
              variant="contained"
              color="primary"
              sx={{ mr: 2 }}
              type="submit"
              disabled={props.blockAccess === true ? true : false}
              startIcon={<Iconify icon="eva:log-in-fill" />}>
              Fazer login
            </Button>
          </Box>
        </form>
      )}
      {props.urlClient && (
        <form onSubmit={HandleSubmit} className="formLogin">
          <Stack spacing={3}>
            <InputMask
              mask="999.999.999-99"
              value={infosLogin.cpf || ''}
              onChange={(event) => HandleInputChange(event)}
              onBlur={(event) => validCPF(event)}>
              {() => (
                <TextField
                  name="cpf"
                  fullWidth
                  label="CPF"
                  required
                  variant="outlined"
                  color="primary"
                />
              )}
            </InputMask>
            <TextField
              id="password"
              name="password"
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              onChange={(event) => HandleInputChange(event)}
              value={infosLogin.password}
              inputProps={{ maxLength: 50 }}
              color="primary"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Grid container>
              <Grid item xs={12} md={4}>
                <FormControl component="fieldset" variant="standard">
                  <FormControlLabel
                    control={
                      <Radio
                        checked={infosLogin.typeClient === 'benef'}
                        onChange={HandleInputChange}
                        value="benef"
                        name="typeClient"
                        required
                      />
                    }
                    label="Beneficiário"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={8}>
                <FormControl component="fieldset" variant="standard">
                  <FormControlLabel
                    control={
                      <Radio
                        checked={infosLogin.typeClient === 'resp_jur'}
                        onChange={HandleInputChange}
                        value="resp_jur"
                        name="typeClient"
                        required
                      />
                    }
                    label="Responsável Jurídico"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Stack>
          <Box mt={3}>
            <Button
              id="login"
              variant="contained"
              color="primary"
              sx={{ mr: 2, textTransform: 'none' }}
              disabled={props.blockAccess === true ? true : false}
              type="submit"
              startIcon={<Iconify icon="eva:log-in-fill" />}>
              Fazer login
            </Button>
          </Box>
        </form>
      )}
    </>
  );
};

export default LoginForm;
