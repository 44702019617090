/* eslint-disable react/prop-types */
import React from 'react';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';

import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';

import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

const UpdatePinCode = (props) => {
  function HandleInputChange(event) {
    props.setInfosBenef({ ...props.infosBenef, [event.target.name]: event.target.value });
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };
  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => props.setOpenModalUpdatePinCode(false)}
          aria-labelledby="customized-dialog-title"
          open={props.openModalUpdatePincode}
          fullWidth
          maxWidth={'md'}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => props.setOpenModalUpdatePinCode(false)}>
            Atualizar pin code
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <TextField
              name="pincode"
              fullWidth
              label="Pin code"
              variant="outlined"
              type="number"
              size="small"
              onChange={(event) => HandleInputChange(event)}
              required
              value={props.infosBenef.pincode || ''}
              InputLabelProps={{
                shrink: true
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => props.facialUpdatePinCode(props.infosBenef.facialid)}
              color="success"
              variant="contained"
              startIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}>
              Atualizar
            </Button>
            <Button
              onClick={() => props.setOpenModalUpdatePinCode(false)}
              color="error"
              variant="contained"
              startIcon={<Iconify icon="eva:close-circle-fill" />}>
              Cancelar
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default UpdatePinCode;
