/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { selectLogin, selectClient } from '../../../../store/login/loginSlice';
import { useDebounce } from 'use-debounce';

import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Grid,
  TextField,
  Typography
} from '@mui/material';

import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import faceIO from '@faceio/fiojs';
import FecthBenefs from './FetchBenefs';
import UpdatePinCode from './UpdatePinCode';
import axios from 'axios';

let faceio;

const FacialRecognition = (props) => {
  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });
  const [openModalFetchBenef, setOpenModalFetchBenef] = useState(false);
  const [openModalDeleteFacial, setOpenModalDeleteFacial] = useState(false);
  const [openModalUpdatePincode, setOpenModalUpdatePinCode] = useState(false);
  const [infosBenef, setInfosBenef] = useState({});

  const company = useSelector(selectClient);
  const keys = useRef(1);
  const [valueKeys] = useDebounce(keys.current, 1000);

  useEffect(() => {
    faceio = new faceIO('fioa4bd1');
  }, []);

  async function handleStatus() {
    const urlIntegration = infoState.rotapostback;

    await axios
      .get(`${urlIntegration}/faceio/status`, {
        headers: {
          authorization: `Bearer ${infoState.tokenpostback}`
        }
      })
      .then(() => {
        NotificationManager.success('Serviço facial em operação!', 'Sucesso');
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para verificar o serviço facial, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  const handleRegister = async () => {
    props.setOpenModal(false);

    try {
      let response = await faceio.enroll({
        locale: 'auto',
        payload: {
          codtit: infosBenef.codtit,
          codusu: infosBenef.codusu
        }
      });
      facialRegister(response.facialId);
      faceio.restartSession({});
    } catch {
      NotificationManager.warning(
        'Tivemos dificuldades para registrar a facial, tente novamente!',
        'Atenção'
      );
      faceio.restartSession({});
    }
  };

  async function facialRegister(facialId) {
    const urlIntegration = infoState.rotapostback;

    const postObject = {
      codtit: infosBenef.codtit,
      codusu: infosBenef.codusu,
      facialid: facialId
    };

    await axios
      .post(`${urlIntegration}/faceio/facialRegister`, postObject, {
        headers: {
          authorization: `Bearer ${infoState.tokenpostback}`
        }
      })
      .then(() => {
        setInfosBenef({});
        faceio.restartSession({});
        keys.current = Math.random();
        NotificationManager.success('Facial registrada com sucesso!', 'Sucesso');
      })
      .catch(() => {
        facialDelete(facialId, true);
      });
  }

  async function facialDelete(facialId, error) {
    const urlIntegration = infoState.rotapostback;

    const postObject = {
      facialId: facialId,
      error: error
    };

    try {
      await axios.post(`${urlIntegration}/faceio/facialDelete`, postObject, {
        headers: {
          authorization: `Bearer ${infoState.tokenpostback}`
        }
      });
      keys.current = Math.random();

      if (error) {
        NotificationManager.warning(
          'Tivemos dificuldades para registrar a facial, tente novamente!',
          'Atenção'
        );
      } else {
        setInfosBenef({});
        setOpenModalDeleteFacial(false);
        NotificationManager.success('Facial excluída com sucesso!', 'Sucesso');
      }
    } catch {
      if (error) {
        NotificationManager.warning(
          'Tivemos dificuldades para registrar a facial, tente novamente!',
          'Atenção'
        );
      } else {
        NotificationManager.warning(
          'Tivemos dificuldades para excluir a facial, tente novamente!',
          'Atenção'
        );
      }
    }
  }

  async function facialUpdatePinCode(facialId) {
    const urlIntegration = infoState.rotapostback;

    if (!infosBenef.pincode) {
      NotificationManager.warning('Preencha o novo pin code para atualizar!', 'Atenção');
      return;
    }

    const postObject = {
      facialId: facialId,
      pin: infosBenef.pincode
    };

    try {
      await axios.post(`${urlIntegration}/faceio/facialUpdatePinCode`, postObject, {
        headers: {
          authorization: `Bearer ${infoState.tokenpostback}`
        }
      });
      keys.current = Math.random();
      setOpenModalUpdatePinCode(false);
      NotificationManager.success('Pin code atualizado com sucesso!', 'Sucesso');
    } catch (err) {
      //   console.log(err);
      NotificationManager.warning(
        'Tivemos dificuldades para atualizar o pin code, por favor, tente novamente!',
        'Atenção'
      );
    }
  }

  const handleAutheticated = async () => {
    props.setOpenModal(false);

    faceio
      .authenticate({
        locale: 'auto' // Default user locale
      })
      // eslint-disable-next-line no-unused-vars
      .then((userData) => {
        setInfosBenef({});
        faceio.restartSession({});
        keys.current = Math.random();
      })
      .catch(() => {
        faceio.restartSession({});
        keys.current = Math.random();
        NotificationManager.warning(
          'Tivemos dificuldades para autenticar o usuário, tente novamente!',
          'Atenção'
        );
      });
  };

  function ModalHandleDeleteFacial() {
    return (
      <BootstrapDialog
        onClose={() => setOpenModalDeleteFacial(false)}
        aria-labelledby="customized-dialog-title"
        open={openModalDeleteFacial}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setOpenModalDeleteFacial(false)}>
          Confirmação de exclusão
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom variant="subtitle2">
            Deseja realmente excluir a facial do beneficiário selecionado?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => facialDelete(infosBenef.facialid, false)}
            color="success"
            variant="contained"
            startIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}>
            Sim
          </Button>
          <Button
            onClick={() => setOpenModalDeleteFacial(false)}
            color="error"
            variant="contained"
            startIcon={<Iconify icon="eva:close-circle-fill" />}>
            Não
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };
  return (
    <>
      {company === 'homolog' && (
        <FecthBenefs
          openModal={openModalFetchBenef}
          setOpenModal={setOpenModalFetchBenef}
          setInfosBenef={setInfosBenef}
          key={valueKeys}
        />
      )}
      <ModalHandleDeleteFacial />
      <UpdatePinCode
        infosBenef={infosBenef}
        setInfosBenef={setInfosBenef}
        facialUpdatePinCode={facialUpdatePinCode}
        openModalUpdatePincode={openModalUpdatePincode}
        setOpenModalUpdatePinCode={setOpenModalUpdatePinCode}
      />

      <BootstrapDialog
        onClose={() => {
          props.setOpenModal(false);
          setInfosBenef({});
        }}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth={'md'}
        open={props.openModal}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            props.setOpenModal(false);
            setInfosBenef({});
          }}>
          Funções de reconhecimento facial
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Stack direction="row" alignItems="center" justifyContent="center" mb={4}>
            <Grid item xs={12} md={4} mr={0.5}>
              <TextField
                name="codtit"
                fullWidth
                label="Código do titular"
                variant="outlined"
                size="small"
                required
                value={infosBenef.codtit || ''}
                InputProps={{
                  readOnly: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} mr={0.5}>
              <TextField
                name="codusu"
                fullWidth
                label="Código do beneficiário"
                variant="outlined"
                size="small"
                required
                value={infosBenef.codusu || ''}
                InputProps={{
                  readOnly: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} mr={0.5}>
              <TextField
                name="nomeusu"
                fullWidth
                label="Nome do beneficiário"
                variant="outlined"
                size="small"
                required
                value={infosBenef.nomeusu || ''}
                InputProps={{
                  readOnly: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} ml={1}>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => setOpenModalFetchBenef(true)}
                startIcon={<Iconify icon="eva:funnel-outline" />}
                sx={{ mr: 2 }}>
                Buscar
              </Button>
            </Grid>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            mb={2}
            spacing={1}
            mr={2}>
            <>
              <Button
                variant="outlined"
                onClick={handleStatus}
                disabled={!infosBenef.codtit ? true : false}
                startIcon={<Iconify icon="eva:info-outline" />}>
                Status
              </Button>
              <Button
                variant="outlined"
                onClick={handleRegister}
                color="success"
                disabled={!infosBenef.facialid && !infosBenef.codtit ? true : false}
                startIcon={<Iconify icon="eva:save-outline" />}>
                Registrar
              </Button>
              <Button
                variant="outlined"
                onClick={handleAutheticated}
                disabled={!infosBenef.facialid ? true : false}
                color="success"
                startIcon={<Iconify icon="eva:lock-outline" />}>
                Autenticar
              </Button>
              <Button
                variant="outlined"
                onClick={() => setOpenModalUpdatePinCode(true)}
                disabled={!infosBenef.facialid ? true : false}
                color="warning"
                startIcon={<Iconify icon="eva:refresh-outline" />}>
                Atualizar
              </Button>
              <Button
                variant="outlined"
                disabled={!infosBenef.facialid ? true : false}
                onClick={() => setOpenModalDeleteFacial(true)}
                color="error"
                startIcon={<Iconify icon="eva:close-circle-outline" />}>
                Excluir
              </Button>
            </>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.setOpenModal(false);
              setInfosBenef({});
            }}
            color="error"
            startIcon={<Iconify icon="eva:close-circle-fill" />}
            variant="contained">
            Fechar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default FacialRecognition;
