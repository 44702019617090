/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material';

import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import useWebSocket from 'react-use-websocket';

const finishSigningSuccess = (props) => {
  const user = useSelector((state) => state.login.authenticatedUser);

  const wsURL =
    process.env.NODE_ENV === 'production'
      ? 'wss://app.vendasplanoonline.com.br:3014'
      : 'ws://localhost:3014';

  let queryParams = {
    'x-access-token': user.token,
    eventType: 'message',
    direction: 'operadora',
    id_client: props.id_client,
    disabledNotification: true
  };

  const { sendMessage } = useWebSocket(wsURL, {
    queryParams,
    share: true
  });

  useEffect(() => {
    if (props.disabledNotification === true) {
      sendMessage();
    }
  }, [props.disabledNotification]);

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setModalAlertOpen(false);
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'sm'}
          scroll="paper"
          open={props.modalAlertOpen && !props.id_profile}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              props.setModalAlertOpen(false);
            }}>
            Parabéns!!
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography variant="subtitle2">
              Você finalizou todas as etapas com sucesso!! Agora é só aguardar, em breve entraremos
              em contato!!
              <span role="img" aria-label="success">
                🎉🎉🎉👏
              </span>
            </Typography>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button
              onClick={() => {
                props.setModalAlertOpen(false);
              }}
              color="primary"
              variant="outlined"
              startIcon={<Iconify icon="eva:close-circle-fill" />}>
              Fechar
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default finishSigningSuccess;
