import React, { useEffect, useState } from 'react';
import { ContentStyle } from '../../../../assets/style/globalStyle';
import { Typography, Card, Stack, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { logout, client as clientDispatch } from '../../../../store/login/loginSlice';
import { NotificationManager } from 'react-notifications';

import RenderPage from '../../../layouts/RenderPage';
import LoginForm from '../../../sections/auth/login';
import imglogo from '../../../../assets/images/multiples/bigLogo.png';
import configAxios from '../../../../services/configAxios';

export default function Login() {
  const [logoOperator, setlogoOperator] = useState('');
  const [urlClient, setUrlClient] = useState(false);
  const [blockAccess, setBlockAccess] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    const urlTemp = window.location.pathname.split('/');
    if (urlTemp[1] === 'portal') {
      setUrlClient(true);
    }
    if (urlTemp[1] !== 'login' && urlTemp[1] !== '' && urlTemp[1] !== 'portal') {
      getCompanyClient(urlTemp[1].toUpperCase());
    } else if (urlTemp[1] !== 'login' && urlTemp[1] !== '') {
      getCompanyClient(urlTemp[2].toUpperCase());
    } else {
      setlogoOperator(imglogo);
    }
  }, []);

  async function getCompanyClient(client) {
    const infosLogin = {
      company: client
    };
    await configAxios
      .post('/utilsInfos/logoOperator', infosLogin)
      .then((response) => {
        setlogoOperator(response.data[0].logo);
        dispatch(clientDispatch(client.toLowerCase()));
      })
      .catch(() => {
        NotificationManager.warning('Cliente não encontrado, tente novamente!', 'Atenção');
        setBlockAccess(true);
        setlogoOperator(imglogo);
      });
  }

  function Logo() {
    if (logoOperator) {
      return (
        <>
          <Box component="img" src={`${logoOperator}`} sx={{ width: '70%' }} />
          <Typography variant="h4" gutterBottom sx={{ pt: 2, ml: 1 }}></Typography>
        </>
      );
    }
  }

  return (
    <RenderPage title="Login">
      <ContentStyle>
        <Card variant="outlined" sx={{ padding: '10%' }}>
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mb: 3 }}>
            <Logo />
          </Stack>
          <Typography sx={{ color: 'text.secondary', mb: 2 }}>
            Utilize as suas credencias para acessar
          </Typography>
          <LoginForm urlClient={urlClient} blockAccess={blockAccess} />
        </Card>
      </ContentStyle>
    </RenderPage>
  );
}
