import { Navigate } from 'react-router-dom';

import DashboardLayout from '../views/layouts/AppLayout';
import BackGroundOnlyLayout from '../views/layouts/BackGroundOnlyLayout';

import Login from '../views/pages/app/auth/Login';
import DashboardApp from '../views/pages/app/dashboard/DashboardApp';

import Signature from '../views/pages/app/signature';

import NotFound from '../views/pages/app/notfound/Page404';

import Clients from '../views/pages/app/clients';
import AddEditClients from '../views/pages/app/clients/AddEditClients';

import MyAccount from '../views/pages/app/account';

import Users from '../views/pages/app/users';
import AddEditUsers from '../views/pages/app/users/AddEditUsers';

import Tables from '../views/pages/app/tables';
import AddEditTables from '../views/pages/app/tables/AddEditTables';

import Readjustments from '../views/pages/app/tables/Readjustments';

import Products from '../views/pages/app/products';
import AddEditProducts from '../views/pages/app/products/AddEditProducts';

import Brokers from '../views/pages/app/brokers';
import AddEditBroker from '../views/pages/app/brokers/AddEditBroker';

import Forms from '../views/pages/app/forms';
import AddEditForm from '../views/pages/app/forms/AddEditForm';

import Simulations from '../views/pages/app/simulations/index';
import AddSimulations from '../views/pages/app/simulations/AddEditSimulations';

import RepSales from '../views/pages/app/reports/RepSales';
import RepSimulations from '../views/pages/app/reports/RepSimulations';

import Config from '../views/pages/app/config';
import Email from '../views/pages/app/email';

import Permissions from '../views/pages/app/permissions';

import DeclarationHealth from '../views/pages/app/declarationHealth';

import Associations from '../views/pages/app/associations';
import AddEditAssociation from '../views/pages/app/associations/AddEditAssociation';

import Operators from '../views/pages/app/operators';
import AddEditOperator from '../views/pages/app/operators/AddEditOperator';

import Shortage from '../views/pages/app/shortages';
import AddEditShortages from '../views/pages/app/shortages/addEditShortages';

import Warnings from '../views/pages/app/warnings';
import AddEditWarnings from '../views/pages/app/warnings/addEditWarnings';

import Comissions from '../views/pages/app/commissions';

const routes = (isLoggedIn, permissions) => {
  const routers = [
    {
      path: '/app',
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { name: 'Dashboard', path: 'dashboard', element: <DashboardApp />, exact: true },
        {
          name: 'Clientes',
          path: 'clients',
          element: (
            <Clients
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Vendas', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Clientes',
          path: 'addClients',
          element: (
            <AddEditClients
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Vendas', path: '/app/clients', active: false },
                { name: 'Cadastro de vendas', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Clientes',
          path: 'editClients/:id',
          element: (
            <AddEditClients
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Vendas', path: '/app/clients', active: false },
                { name: 'Cadastro de vendas', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Minha conta',
          path: 'myAccount',
          element: (
            <MyAccount
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Minha conta', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Usuários',
          path: 'users',
          element: (
            <Users
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Usuários', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Usuários',
          path: 'addUsers',
          element: (
            <AddEditUsers
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Usuários', path: '/app/users', active: false },
                { name: 'Cadastro de usuários', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Usuários',
          path: 'editUsers/:id',
          element: (
            <AddEditUsers
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Usuários', path: '/app/users', active: false },
                { name: 'Cadastro de usuários', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Tabelas',
          path: 'tables',
          element: (
            <Tables
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Tabelas', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Tabelas',
          path: 'addTables',
          element: (
            <AddEditTables
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Tabelas', path: '/app/tables', active: false },
                { name: 'Cadastro de tabelas', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Tabelas',
          path: 'editTables/:id',
          element: (
            <AddEditTables
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Tabelas', path: '/app/tables', active: false },
                { name: 'Cadastro de tabelas', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Reajustes',
          path: 'readjustments',
          element: (
            <Readjustments
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Tabelas', path: '/app/tables', active: false },
                { name: 'Reajustar tabelas', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Produtos',
          path: 'products',
          element: (
            <Products
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Produtos', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Produtos',
          path: 'addProducts',
          element: (
            <AddEditProducts
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Produtos', path: '/app/products', active: false },
                { name: 'Cadastro de produtos', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Produtos',
          path: 'editProducts/:id',
          element: (
            <AddEditProducts
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Produtos', path: '/app/products', active: false },
                { name: 'Cadastro de produtos', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Carências',
          path: 'shortages',
          element: (
            <Shortage
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Carências', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Carências',
          path: 'addShortages',
          element: (
            <AddEditShortages
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Carências', path: '/app/shortages', active: false },
                { name: 'Cadatro de carências', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Carências',
          path: 'editShortages/:id',
          element: (
            <AddEditShortages
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Carências', path: '/app/shortages', active: false },
                { name: 'Cadastro de carências', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Corretoras',
          path: 'brokers',
          element: (
            <Brokers
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Corretoras', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Corretoras',
          path: 'addBrokers/',
          element: (
            <AddEditBroker
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Corretoras', path: '/app/brokers', active: false },
                { name: 'Cadastro de corretoras', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Corretoras',
          path: 'editBrokers/:id',
          element: (
            <AddEditBroker
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Corretoras', path: '/app/brokers', active: false },
                { name: 'Cadastro de corretoras', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Associações',
          path: 'associations',
          element: (
            <Associations
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Associações', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Associações',
          path: 'addAssociations/',
          element: (
            <AddEditAssociation
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Associações', path: '/app/associations', active: false },
                { name: 'Cadastro de associações', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Associações',
          path: 'editAssociations/:id',
          element: (
            <AddEditAssociation
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Associações', path: '/app/associations', active: false },
                { name: 'Cadastro de associações', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Operadoras',
          path: 'operators',
          element: (
            <Operators
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Operadoras', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Operadoras',
          path: 'addOperators/',
          element: (
            <AddEditOperator
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Operadoras', path: '/app/operators', active: false },
                { name: 'Cadastro de operadoras', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Operadoras',
          path: 'editOperators/:id',
          element: (
            <AddEditOperator
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Operadoras', path: '/app/operators', active: false },
                { name: 'Cadastro de operadoras', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Formulários',
          path: 'forms',
          element: (
            <Forms
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Formulários', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Formulários',
          path: 'addForm',
          element: (
            <AddEditForm
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Formulários', path: '/app/forms', active: false },
                { name: 'Cadastro de formulários', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Formulários',
          path: 'editForm/:id',
          element: (
            <AddEditForm
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Formulários', path: '/app/forms', active: false },
                { name: 'Cadastro de formulários', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Declaração de saúde',
          path: 'declarationHealth',
          element: (
            <DeclarationHealth
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Declaração de saúde', active: true }
              ]}
            />
          ),
          exact: true
        },

        {
          name: 'Avisos',
          path: 'warnings',
          element: (
            <Warnings
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Avisos', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Avisos',
          path: 'addWarning/',
          element: (
            <AddEditWarnings
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Avisos', path: '/app/warnings', active: false },
                { name: 'Cadastro de avisos', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Avisos',
          path: 'editWarning/:id',
          element: (
            <AddEditWarnings
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Avisos', path: '/app/warnings', active: false },
                { name: 'Cadastro de avisos', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Relatório de vendas',
          path: 'reports/repSales',
          element: (
            <RepSales
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Relatório de vendas', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Relatório de simulações',
          path: 'reports/repSimulations',
          element: (
            <RepSimulations
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Relatório de simulações', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Comissões',
          path: 'comissions',
          element: (
            <Comissions
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Comissões', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Simulações',
          path: 'simulations',
          element: (
            <Simulations
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Simulações', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Simulações',
          path: 'addSimulations',
          element: (
            <AddSimulations
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Simulações', path: '/app/simulations', active: false },
                { name: 'Cadastro de simulações', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Simulações',
          path: 'editSimulations/:id',
          element: (
            <AddSimulations
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Simulações', path: '/app/simulations', active: false },
                { name: 'Cadastro de simulações', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Configurações do sistema',
          path: 'config',
          element: (
            <Config
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Configurações do sistema', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Configurações de email',
          path: 'email',
          element: (
            <Email
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Configurações de email', active: true }
              ]}
            />
          ),
          exact: true
        },
        {
          name: 'Permissões padrões',
          path: 'permissions',
          element: (
            <Permissions
              breadcrumb={[
                { name: 'Dashboard', path: '/app/dashboard', active: false },
                { name: 'Permissões padrões', active: true }
              ]}
            />
          )
        },
        {
          name: 'Assinaturas',
          path: 'signature',
          element: <Signature breadcrumb={[{ name: 'Assinatura de documentos', active: true }]} />
        }
      ]
    },
    {
      path: '/',
      element: <BackGroundOnlyLayout />,
      children: [
        { path: '/', element: <Login /> },
        { path: '/:cliente', element: <Login /> },
        { path: '/:cliente/client', element: <Login /> },
        { path: 'login', element: <Login />, exact: true },
        { path: '404', element: <NotFound />, exact: true },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: 'portal',
      element: <BackGroundOnlyLayout />,
      children: [
        { path: '/portal', element: <NotFound /> },
        { path: ':client', element: <Login /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ];

  const routes = [];

  if (!permissions) {
    return routers;
  }

  routers.map((item) => {
    if (item.path == '/') {
      routes.push(item);
    }
    if (item.path == 'portal') {
      routes.push(item);
    }
    if (item.path == '*') {
      routes.push(item);
    }
    if (item.path === '/app' && item.children) {
      item.children.map((child) => {
        permissions.map((permission) => {
          if (child.name === permission.name) {
            routes.push({
              path: '/app',
              element: <DashboardLayout />,
              children: [child]
            });
          }
        });
      });
    }
  });

  return routes;
};

export default routes;
