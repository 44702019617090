import React, { useState, useEffect } from 'react';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Autocomplete
} from '@mui/material';

import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import configAxios from '../../../../services/configAxios';

/**
 * id_type_client === 1 - Físico
 * id_type_client === 2 - Júridico
 *
 */

const addEditRangeSimulations = (props) => {
  const [infosCalculated, setInfosCalculated] = useState({});
  const [infosRange, setInfosRange] = useState([]);

  useEffect(() => {
    if (props.otherInfos.codtab) {
      getRangeValues();
    }
  }, [props.otherInfos.codtab]);

  useEffect(() => {
    if (props.infosEdit[0]) {
      const moneyRange = infosRange.filter((element) => {
        if (element.label == props.infosEdit[0].faixa_etaria) {
          return element;
        }
      });
      setInfosCalculated({
        id: props.infosEdit[0].id,
        label: props.infosEdit[0].faixa_etaria,
        qtdevidas: props.infosEdit[0].qtdevidas,
        moneyValue: props.infosEdit[0].valsimulation,
        moneyRange: moneyRange[0] ? moneyRange[0].valor : '',
        tpbenef: props.infosEdit[0].tpbenef
      });
    } else {
      setInfosCalculated({});
    }
  }, [props.infosEdit]);

  function getRangeValues() {
    configAxios
      .get(`tables/getRangeValues/${props.otherInfos.codtab}`)
      .then((result) => {
        setInfosRange(result.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações das faixas etárias! Tente novamente!',
          'Atenção'
        );
      });
  }

  function calculatedValueRange() {
    if (!infosCalculated.qtdevidas) {
      NotificationManager.warning('Por favor, informe a quantidade para calcular!', 'Atenção');
    }
    setInfosCalculated({
      ...infosCalculated,
      moneyValue: (infosCalculated.qtdevidas * infosCalculated.moneyRange).toFixed(2)
    });
  }

  function HandleInputChange(event, autocomplete) {
    if (autocomplete) {
      if (event) {
        setInfosCalculated({
          ...infosCalculated,
          label: event.label,
          moneyRange: event.valor,
          moneyValue: ''
        });
        return;
      }
      return;
    }
    setInfosCalculated({ ...infosCalculated, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    if (!infosCalculated.moneyValue) {
      NotificationManager.warning('Por favor, faça o cálculo para continuar!', 'Atenção');
      return;
    }
    const data = {
      id: infosCalculated.id ? infosCalculated.id : null,
      id_simulation: props.otherInfos.id,
      type_cad: props.otherInfos.id_type_client === 2 ? 'jurídico' : 'físico',
      tpbenef: infosCalculated.tpbenef,
      qtdevidas: infosCalculated.qtdevidas,
      valsimulation: infosCalculated.moneyValue,
      faixa_1: +infosCalculated.label.split('-')[0].trim(),
      faixa_2: +infosCalculated.label.split('-')[1].trim()
    };
    const router = infosCalculated.id ? 'simulations/updateValues' : 'simulations/insertValues';
    configAxios
      .post(router, data)
      .then((result) => {
        if (result.status === 202) {
          NotificationManager.warning(
            'Só é permitido um titular por simulação pessoa física!',
            'Atenção'
          );
        } else {
          NotificationManager.success('Operação realizada com sucesso!', 'Sucesso');
          props.setOpenModal(false);
          props.functionGrid();
        }
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
            props.functionGrid();
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'md'}
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
              props.functionGrid();
            }}>
            {infosCalculated.id ? 'Editar faixas etárias' : 'Cadastrar faixas etárias'}
          </BootstrapDialogTitle>

          <form onSubmit={HandleSubmit}>
            <DialogContent dividers>
              <Grid container spacing={2} sx={{ mb: 1 }}>
                <Grid item xs={12} md={3}>
                  <InputLabel id="label" sx={{ fontSize: '13px' }}>
                    Faixa etária *
                  </InputLabel>
                  <Autocomplete
                    options={infosRange}
                    renderOption={(props, option) => <li {...props}>{option.label}</li>}
                    isOptionEqualToValue={(option, value) =>
                      value ? option.label === value : option.label === ''
                    }
                    onChange={(event, value) => HandleInputChange(value, true)}
                    value={infosCalculated.label || ''}
                    defaultValue=""
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="range"
                        fullWidth
                        size="small"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputLabel id="label" sx={{ fontSize: '13px' }}>
                    Tipo de beneficiário *
                  </InputLabel>
                  <Select
                    name="tpbenef"
                    fullWidth
                    size="small"
                    required
                    value={infosCalculated.tpbenef || ''}
                    onChange={(event) => HandleInputChange(event)}>
                    <MenuItem value={'Titular'}>Titular</MenuItem>
                    <MenuItem value={'Dependente'}>Dependente</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={3} sx={isMobile ? { mt: 1 } : { mt: 2.3 }}>
                  <TextField
                    name="qtdevidas"
                    fullWidth
                    label="Quantidade"
                    variant="outlined"
                    size="small"
                    required
                    onChange={(event) => HandleInputChange(event)}
                    value={infosCalculated.qtdevidas || ''}
                    onBlur={() => calculatedValueRange()}
                  />
                </Grid>
                <Grid item xs={12} md={3} sx={isMobile ? { mt: 1 } : { mt: 2.3 }}>
                  <TextField
                    name="moneyValue"
                    fullWidth
                    label="Valor R$"
                    variant="outlined"
                    size="small"
                    disabled
                    required
                    value={infosCalculated.moneyValue || ''}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="success"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-fill" />}>
                {infosCalculated.id ? 'Atualizar' : 'Salvar'}
              </Button>
              <Button
                onClick={() => {
                  props.setOpenModal(false);
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Cancelar
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default addEditRangeSimulations;
