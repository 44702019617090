/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, DialogTitle, Button, Grid } from '@mui/material';
import { NotificationManager } from 'react-notifications';
import { CustomInfosForm, StyledMenu } from '../../../../assets/style/globalStyle';
import { isMobile } from 'react-device-detect';
import { selectLogin } from '../../../../store/login/loginSlice';

import configAxios from '../../../../services/configAxios';
import SigningDocuments from './SigningDocuments';
import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PendingHealthDeclaration from './PendingHealthDeclaration';

const documentsForSignature = (props) => {
  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  const [documents, setDocuments] = useState([]);
  const [documentsSigning, setDocumentsSigning] = useState({});
  const [documentsForSignature, setDocumentsForSignature] = useState({});
  const [pdf, setpdf] = useState(null);
  const [openModalSignatureDocuments, setOpenModalSignatureDocuments] = useState(false);
  const [openModalPendingHealth, setOpenModalPendingHealth] = useState(false);
  const [pendingInfosDeclarationHealth, setPendingInfosDeclarationHealth] = useState([]);
  const [headerModalPending, setHeaderModalPending] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const user = useSelector((state) => state.login.authenticatedUser);
  let tpbenef = useSelector((state) => state.login.type);

  useEffect(() => {
    getDocumentsForSinagature();
    getDocumentsSigning();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function getDocumentsForSinagature() {
    const titbenef = user.userLoggedIn[0].titbenef ? user.userLoggedIn[0].titbenef : 'undefined';
    const tpcontrat = user.userLoggedIn[0].tpcontrat;
    const id_dep = user.userLoggedIn[0].id;
    const codplano = user.userLoggedIn[0].codplano;
    const id_client = user.userLoggedIn[0].id_client;

    if (tpcontrat) {
      let clientForSignatureDocuments = {};
      clientForSignatureDocuments = {
        tpbenef,
        tpcontrat,
        titbenef,
        id_dep,
        id_client,
        codplano,
        included_resp: 'N'
      };
      if (user.userLoggedIn[0].cpf_cnpj === user.userLoggedIn[0].cpf_cnpj_resp) {
        clientForSignatureDocuments = {
          tpbenef,
          tpcontrat,
          titbenef,
          id_dep,
          id_client,
          codplano,
          included_resp: 'S'
        };
      }

      configAxios
        .post(`/signing/getDocumentsForSignature`, clientForSignatureDocuments)
        .then((result) => {
          setDocuments(result.data);
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para buscar os documentos para assinatura, tente novamente!',
            'Atenção'
          );
        });
    } else {
      let clientForSignatureDocuments = {};
      clientForSignatureDocuments = {
        tpcontrat: props.infosClients.tpcontrat,
        id_client: props.infosClients.id,
        statusvenda: props.infosClients.statusvenda,
        id_profile: user.userLoggedIn[0].id_profile,
        codplano: codplano
      };
      configAxios
        .post(`/signing/getDocumentsForSignature`, clientForSignatureDocuments)
        .then((result) => {
          setDocuments(result.data);
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para buscar os documentos para assinatura, tente novamente!',
            'Atenção'
          );
        });
    }
  }

  function getDocumentsSigning() {
    const tpcontrat = user.userLoggedIn[0].tpcontrat;
    if (tpcontrat) {
      const client = {
        userLoggedIn: { ...user.userLoggedIn[0] },
        tpcontrat: tpcontrat,
        tpbenef: tpbenef
      };
      configAxios
        .post(`/signing/getDocumentsSigning`, client)
        .then((result) => {
          setDocumentsSigning(result.data);
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para buscar os documentos assinados, tente novamente!',
            'Atenção'
          );
        });
    } else {
      const client = {
        userLoggedIn: { ...user.userLoggedIn[0] },
        tpcontrat: props.infosClients.tpcontrat,
        id_client: props.infosClients.id,
        id_profile: user.userLoggedIn[0].id_profile,
        statusvenda: props.infosClients.statusvenda
      };

      configAxios
        .post(`/signing/getDocumentsSigning`, client)
        .then((result) => {
          setDocumentsSigning(result.data);
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para buscar os documentos assinados, tente novamente!',
            'Atenção'
          );
        });
    }
  }

  async function generatePdfSigning(event) {
    let infos;
    if (tpbenef) {
      infos = {
        userLoggedIn: { ...user.userLoggedIn[0] },
        idDoc: event.id,
        document: event.document,
        documentName: event.nome,
        tipo: event.tipo,
        tpdoc: event.tpdoc,
        tpbenef: tpbenef
      };
    } else {
      infos = {
        userLoggedIn: { ...user.userLoggedIn[0] },
        infosSocialClients: props.infosPJ,
        idDoc: event.id,
        document: event.document,
        documentName: event.nome,
        tipo: event.tipo,
        tpdoc: event.tpdoc,
        id_profile: user.userLoggedIn[0].id_profile,
        id_client: props.infosClients.id,
        statusvenda: props.infosClients.statusvenda,
        infosClients: props.infosClients
      };
    }
    await configAxios
      .post(`/signing/generatePdfSigning/`, infos, {
        responseType: 'blob'
      })
      .then((result) => {
        const blob = URL.createObjectURL(result.data);
        setpdf(blob);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos para gerar o documento para assinatura! Tente novamente!',
          'Atenção'
        );
      });
  }

  function handleSigningDocument(event) {
    //tirar após assinatura infinity
    if (!props.formClient) {
      const id_client = props.infosPJ.current.id
        ? props.infosPJ.current.id
        : user.userLoggedIn[0].id_client;

      if (id_client === 6 && tpbenef === 'resp_jur') {
        setpdf();
        setDocumentsForSignature(event);
        generatePdfSigning(event);
        setOpenModalSignatureDocuments(true);
        return;
      }
    }
    /**end tirar após assinatura infinity */

    if (
      !props.formClient &&
      infoState.tipooperacao !== 'Cartão' &&
      infoState.tipooperacao !== 'Odonto'
    ) {
      const notCompletedHealth = props.infosClients.filter(
        (element) => element.completedhealthdeclaration === false
      );
      if (
        tpbenef === 'resp_jur' ||
        user.userLoggedIn[0].cpf_cnpj === user.userLoggedIn[0].cpf_cnpj_resp
      ) {
        const id_client = props.infosPJ.current.id
          ? props.infosPJ.current.id
          : user.userLoggedIn[0].id_client;

        //tpclient 1 - físico / 2 - júridico

        const tpclient = props.infosPJ.current.id ? '2' : '1';

        configAxios.get(`/clients/pendingDeclarationHealth/${id_client}/false`).then((result) => {
          if (result.data.length > 0) {
            setPendingInfosDeclarationHealth(result.data);
            setHeaderModalPending('Declarações de saúde pendentes');
            setOpenModalPendingHealth(true);
            NotificationManager.warning(
              'É necessário que todos preencham as declarações de saúde para assinar os documentos!',
              'Atenção'
            );
          } else {
            configAxios
              .get(
                `/clients/pendingSigningDocuments/${id_client}/${user.userLoggedIn[0].id}/${tpclient}/false`
              )
              .then((result) => {
                if (result.data.length > 0) {
                  setPendingInfosDeclarationHealth(result.data);
                  setHeaderModalPending('Assinaturas de documentos pendentes');
                  setOpenModalPendingHealth(true);
                  NotificationManager.warning(
                    'É necessário que todos assinem os documentos!',
                    'Atenção'
                  );
                } else {
                  setpdf();
                  setDocumentsForSignature(event);
                  generatePdfSigning(event);
                  setOpenModalSignatureDocuments(true);
                }
              });
          }
        });
        return;
      }
      if (notCompletedHealth.length > 0) {
        const message =
          infoState.id_type_client === 2
            ? 'É necessário que todos preencham as declarações de saúde para assinar os documentos!'
            : 'É necessário preencher todas as declarações de saúde para assinar os documentos!';

        NotificationManager.warning(message, 'Atenção');
        return;
      }
    } else if (
      !props.formClient &&
      (infoState.tipooperacao === 'Cartão' || infoState.tipooperacao === 'Odonto')
    ) {
      const id_client = props.infosPJ.current.id
        ? props.infosPJ.current.id
        : user.userLoggedIn[0].id_client;

      //tpclient 1 - físico / 2 - júridico
      const tpclient = props.infosPJ.current.id ? '2' : '1';

      if (
        tpbenef === 'resp_jur' ||
        user.userLoggedIn[0].cpf_cnpj === user.userLoggedIn[0].cpf_cnpj_resp
      ) {
        configAxios
          .get(
            `/clients/pendingSigningDocuments/${id_client}/${user.userLoggedIn[0].id}/${tpclient}/false`
          )
          .then((result) => {
            if (result.data.length > 0) {
              setPendingInfosDeclarationHealth(result.data);
              setHeaderModalPending('Assinaturas de documentos pendentes');
              setOpenModalPendingHealth(true);
              NotificationManager.warning(
                'É necessário que todos assinem os documentos!',
                'Atenção'
              );
            } else {
              setpdf();
              setDocumentsForSignature(event);
              generatePdfSigning(event);
              setOpenModalSignatureDocuments(true);
            }
          });
        return;
      }
    }
    setpdf();
    setDocumentsForSignature(event);
    generatePdfSigning(event);
    setOpenModalSignatureDocuments(true);
  }

  function RenderDocumentsForSignature() {
    let signed = [];
    let needToSign = [];
    let renderDocuments = [];
    let item;

    if (user.userLoggedIn[0].id_profile === 3) {
      signed = documents.filter((object1) => {
        return documentsSigning.some((object2) => {
          return object1.tpdoc === object2.tpdoc && object1.id_dep === object2.id_dep;
        });
      });

      needToSign = documents.filter((object1) => {
        return !documentsSigning.some((object2) => {
          return object1.tpdoc === object2.tpdoc && object1.id_dep === object2.id_dep;
        });
      });
    } else {
      signed = documents.filter((object1) => {
        return documentsSigning.some((object2) => {
          return object1.tpdoc === object2.tpdoc;
        });
      });

      needToSign = documents.filter((object1) => {
        return !documentsSigning.some((object2) => {
          return object1.tpdoc === object2.tpdoc;
        });
      });
    }
    signed.map((element, key) => {
      item = (
        <div key={key}>
          <MenuItem disabled disableRipple>
            <CustomInfosForm>{element.nome}</CustomInfosForm>
          </MenuItem>
        </div>
      );
      renderDocuments.push(item);
    });
    needToSign.map((element, key) => {
      item = (
        <div key={key + 10}>
          <MenuItem disableRipple>
            <div>
              <CustomInfosForm onClick={() => handleSigningDocument(element)}>
                {element.nome}
              </CustomInfosForm>
            </div>
          </MenuItem>
        </div>
      );
      renderDocuments.push(item);
    });
    return renderDocuments;
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <PendingHealthDeclaration
        openModal={openModalPendingHealth}
        setOpenModal={setOpenModalPendingHealth}
        pendingInfos={pendingInfosDeclarationHealth}
        functionGrid={props.functionGrid}
        headerModalPending={headerModalPending}
      />

      <div>
        <Button
          id="customized-button"
          aria-controls={open ? 'customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          color="ligth"
          onClick={handleClick}
          sx={isMobile ? { textTransform: 'none' } : { ml: 1, textTransform: 'none' }}
          startIcon={<Iconify icon="eva:edit-fill" />}
          endIcon={<KeyboardArrowDownIcon />}>
          Assinar
        </Button>
        <StyledMenu
          id="customized-menu"
          MenuListProps={{
            'aria-labelledby': 'customized-button'
          }}
          sx={{
            p: 0,
            mt: 1,
            width: 350
          }}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          onClose={handleClose}>
          <RenderDocumentsForSignature />
        </StyledMenu>

        {pdf && (
          <SigningDocuments
            openModal={openModalSignatureDocuments}
            setOpenModal={setOpenModalSignatureDocuments}
            documentsForSignature={documentsForSignature}
            pdf={pdf}
            qtdDocumentsForSignature={documents.length}
            qtdDocumentSigning={documentsSigning.length}
            getDocumentsForSinagature={getDocumentsForSinagature}
            getDocumentsSigning={getDocumentsSigning}
            pGetDocumentsSigning={props.getDocumentsSigning}
            RenderDocumentsForSignature={RenderDocumentsForSignature}
            infoState={infoState}
            id_client={
              props.infosClients[0] !== undefined
                ? props.infosClients[0].id_client
                : props.infosClients.id
            }
            id_profile={user.userLoggedIn[0].id_profile}
            statusvenda={props.infosClients.statusvenda}
            infosClients={props.infosClients}
            getInfosClient={props.getInfosClient}
            functionGrid={props.functionGrid}
          />
        )}
      </div>
    </>
  );
};

export default documentsForSignature;
