/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { BootstrapDialog, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';

import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import configAxios from '../../../../services/configAxios';

const addEditDeclarationHealth = (props) => {
  const [infosQuestion, setInfosQuestion] = useState({});

  useEffect(() => {
    setInfosQuestion(props.infosItemEdit ? props.infosItemEdit : {});
  }, [props.infosItemEdit]);

  function HandleInputChange(event) {
    setInfosQuestion({ ...infosQuestion, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();

    let router = infosQuestion.id ? '/questions/updateQuestions' : '/questions/addQuestions';

    let notification = infosQuestion.id
      ? 'Pergunta atualizada com sucesso!'
      : 'Pergunta adicionada com sucesso!';

    configAxios
      .post(router, infosQuestion)
      .then(() => {
        props.setOpenModal(false);
        props.functionGrid();
        NotificationManager.success(notification, 'Sucesso');
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
            props.functionGrid();
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'md'}
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
              props.functionGrid();
            }}>
            {infosQuestion.id ? 'Editar pergunta' : 'Adicionar pergunta'}
          </BootstrapDialogTitle>
          <form onSubmit={HandleSubmit}>
            <DialogContent dividers>
              <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
              <Grid container spacing={2} sx={{ mb: 1, mt: 1 }}>
                <Grid item xs={12} md={8}>
                  <TextField
                    name="pergunta"
                    fullWidth
                    label="Pergunta"
                    variant="outlined"
                    size="small"
                    required
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={infosQuestion.pergunta || ''}
                    onChange={(event) => HandleInputChange(event)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel
                    id="label"
                    sx={isMobile ? { fontSize: '13px', mt: 0 } : { fontSize: '13px', mt: -2.3 }}>
                    Selecione o tipo de resposta *
                  </InputLabel>
                  <Select
                    name="tpresposta"
                    fullWidth
                    size="small"
                    required
                    value={infosQuestion.tpresposta || ''}
                    onChange={(event) => HandleInputChange(event)}>
                    <MenuItem value={'Boleana'}>Boleana</MenuItem>
                    <MenuItem value={'Texto'}>Texto</MenuItem>
                    <MenuItem value={'Altura'}>Altura</MenuItem>
                    <MenuItem value={'Peso'}>Peso</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="success"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-fill" />}>
                {infosQuestion.id ? 'Atualizar' : 'Salvar'}
              </Button>
              <Button
                onClick={() => {
                  props.setOpenModal(false);
                  props.functionGrid();
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Cancelar
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default addEditDeclarationHealth;
