import React, { useState, useEffect } from 'react';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';
import { isMobile } from 'react-device-detect';
import { NotificationManager } from 'react-notifications';
import {
  Stack,
  Typography,
  Button,
  Card,
  Grid,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing,
  RowDragging,
  Selection
} from 'devextreme-react/data-grid';

import configAxios from '../../../../services/configAxios';
import AddEditDeclarationHealth from './AddEditDeclarationHealth';
import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

const allowedPageSizes = [5, 10, 20, 30];

const declarationHealth = (props) => {
  useEffect(() => {
    getInfosQuestions();
  }, []);

  const [rowSelected, setRowSelected] = useState();
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  const [infosDeclarationHealth, setInfosDeclarationHealth] = useState([]);

  function selectedRow(event) {
    setRowSelected(event.selectedRowsData[0]);
  }

  function onReorder(event) {
    const visibleRows = event.component.getVisibleRows();
    const newOrder = [...infosDeclarationHealth];

    const toIndex = newOrder.findIndex((item) => item.id === visibleRows[event.toIndex].data.id);
    const fromIndex = newOrder.findIndex((item) => item.id === event.itemData.id);

    newOrder.splice(fromIndex, 1);
    newOrder.splice(toIndex, 0, event.itemData);

    setInfosDeclarationHealth(newOrder);

    SaveOrder(newOrder);
  }

  function SaveOrder(newOrder) {
    configAxios
      .post('/questions/reorderQuestions', newOrder)
      .then(() => {
        NotificationManager.success('Ordem atualizada com sucesso!', 'Sucesso');
        getInfosQuestions();
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atualizar a ordem, tente novamente!',
          'Atenção'
        );
      });
  }

  function AddEditModal(param) {
    if (param === 'open') {
      setRowSelected({});
    }
    setOpenModalEdit(true);
  }

  function getInfosQuestions() {
    configAxios
      .get('/questions/listQuestions')
      .then((response) => {
        setInfosDeclarationHealth(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as perguntas, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleDeleteRow() {
    configAxios
      .delete(`/questions/deleteQuestions/${rowSelected.id}`)
      .then(() => {
        NotificationManager.success('Pergunta excluída com sucesso!', 'Sucesso');
        getInfosQuestions();
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para excluir a pergunta, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <RenderPage title="Declaração de saúde">
      <>
        <AddEditDeclarationHealth
          openModal={openModalEdit}
          setOpenModal={setOpenModalEdit}
          functionGrid={getInfosQuestions}
          infosItemEdit={rowSelected}
        />

        <div>
          <BootstrapDialog
            onClose={() => {
              setModalDeleteOpen(false);
              getInfosQuestions();
            }}
            aria-labelledby="customized-dialog-title"
            fullWidth
            maxWidth={'sm'}
            open={modalDeleteOpen}>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={() => {
                setModalDeleteOpen(false);
                getInfosQuestions();
              }}>
              Excluir pergunta
            </BootstrapDialogTitle>

            <DialogContent dividers>
              <Typography variant="subtitle2">
                Deseja realmente excluir a pergunta selecionada?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  HandleDeleteRow();
                  setModalDeleteOpen(false);
                }}
                size="medium"
                color="success"
                startIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}>
                Sim
              </Button>
              <Button
                onClick={() => {
                  setModalDeleteOpen(false);
                  getInfosQuestions();
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Não
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </div>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro da declaração de saúde
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para criar e editar as perguntas da declaração de saúde
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                onClick={getInfosQuestions}
                sx={{ ml: 1 }}
                startIcon={<Iconify icon="eva:refresh-outline" />}>
                Atualizar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <Stack direction="row" alignItems="center" justifyContent="end">
              {!isMobile && (
                <Grid container>
                  <Grid item xs={12}>
                    <CustomInfosForm>Adicionar e/ou remover perguntas</CustomInfosForm>
                  </Grid>
                </Grid>
              )}
              <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mb: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => AddEditModal('open')}
                  sx={{ mr: 1 }}
                  startIcon={<Iconify icon="eva:plus-fill" />}>
                  Nova
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => AddEditModal('edit')}
                  disabled={!rowSelected}
                  startIcon={<Iconify icon="eva:edit-fill" />}>
                  Editar
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  disabled={!rowSelected}
                  onClick={() => {
                    setModalDeleteOpen(true);
                  }}
                  sx={{ ml: 1 }}
                  startIcon={<Iconify icon="eva:trash-2-outline" />}>
                  Excluir
                </Button>
              </Stack>
            </Stack>
            <DataGrid
              dataSource={infosDeclarationHealth}
              showBorders={true}
              remoteOperations={true}
              wordWrapEnabled={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onSelectionChanged={selectedRow}>
              <Selection mode="single" />
              <RowDragging allowReordering={true} onReorder={onReorder} showDragIcons={true} />
              <Column dataField="pergunta" caption="Pergunta" />
              <Column dataField="tpresposta" caption="Tipo de resposta" />

              <SearchPanel visible={true} width={240} placeholder="Procurar..." />
              {!isMobile && (
                <GroupPanel visible={true} emptyPanelText="Arraste as colunas aqui para agrupar" />
              )}
              <Scrolling rowRenderingMode="virtual"></Scrolling>
              <Paging defaultPageSize={30} />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              <ColumnChooser
                enabled={true}
                title={'Escolher uma coluna'}
                emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
              />
              <ColumnFixing enabled={true} />
              <HeaderFilter visible={true} allowSearch={true} />
            </DataGrid>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default declarationHealth;
