import axios from 'axios';

export function formatDate(date) {
  let dArr = date.split('-');
  const formatedDate = dArr[2] + '/' + dArr[1] + '/' + dArr[0].substring(0, 4);
  return formatedDate;
}

export function validCep(cep) {
  const cepTest = /[0-9]{5}-[\d]{3}/.test(cep);
  return cepTest;
}

export async function viaCep(cep) {
  let infosCepTemp = {};
  let clearCep;
  clearCep = cep.replace('-', '');
  infosCepTemp = await axios.get(`https://viacep.com.br/ws/${clearCep}/json/`);
  if (infosCepTemp) {
    const infosCep = {
      rua: infosCepTemp.data.logradouro,
      cidade: infosCepTemp.data.localidade,
      estado: infosCepTemp.data.uf,
      bairro: infosCepTemp.data.bairro,
      codibge: infosCepTemp.data.ibge
    };
    return infosCep;
  }
  return false;
}

export async function viaCNPJ(cnpj) {
  const cnpjNumber = cnpj.replace(/[^\d]+/g, '');

  const infosFetchCNPJ = await axios.get(`https://publica.cnpj.ws/cnpj/${cnpjNumber}`);

  if (!infosFetchCNPJ.data.error) {
    const infosCNPJ = {
      nome: infosFetchCNPJ.data['razao_social'],
      nomefantasia: infosFetchCNPJ.data['nome_fantasia']
    };
    return infosCNPJ;
  }
  return false;
}

export function formatCnpjCpf(value) {
  const cnpjCpf = value.replace(/\D/g, '');
  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }
  return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
}

export function validateCPF(cpf) {
  if (typeof cpf !== 'string') {
    return false;
  }
  cpf = cpf.replace(/[\s.-]*/gim, '');
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false;
  }
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++) {
    // eslint-disable-next-line radix
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  // eslint-disable-next-line radix
  if (resto !== parseInt(cpf.substring(9, 10))) {
    return false;
  }
  soma = 0;
  for (let i = 1; i <= 10; i++) {
    // eslint-disable-next-line radix
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  // eslint-disable-next-line radix
  if (resto !== parseInt(cpf.substring(10, 11))) {
    return false;
  }
  return true;
}

export function validateCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj == '') return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

export function removeAccents(str) {
  let with_accent = 'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ';
  let no_acento = 'AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr';
  let newstr = '';
  for (let i = 0; i < str.length; i++) {
    let trade = false;
    for (let a = 0; a < with_accent.length; a++) {
      if (str.substr(i, 1) == with_accent.substr(a, 1)) {
        newstr += no_acento.substr(a, 1);
        trade = true;
        break;
      }
    }
    if (!trade) {
      newstr += str.substr(i, 1);
    }
  }
  return newstr;
}

export function validEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
