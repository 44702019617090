import axios from 'axios';
import { startAndStop } from '../store/loading/loadingSlice';
import { logout } from '../store/login/loginSlice';
import { store } from '../store/index';

const baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://app.vendasplanoonline.com.br:3014'
    : 'http://localhost:3014';

const configAxios = axios.create({
  baseURL
});

const requestHandler = (request) => {
  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  const state = store.getState();
  const client = state.login.clientLogin;

  const profile = state.login.authenticatedUser.userLoggedIn
    ? state.login.authenticatedUser.userLoggedIn[0].id_profile
    : null;

  const urlRedirect = profile ? `/${client}` : `/portal/${client}`;

  if (error.response.status === 401) {
    window.location = urlRedirect;
    store.dispatch(logout());
    store.dispatch(startAndStop(false));
    return;
  }
  store.dispatch(startAndStop(false));
  throw error;
};

configAxios.interceptors.request.use(
  (request) => {
    const state = store.getState();
    const userAuth = state.login.authenticatedUser;
    // console.log(userAuth.token);
    if (userAuth.token) {
      const headers = {
        authorization: userAuth.token,
        'x-access-user-id': userAuth.userLoggedIn[0].id,
        'x-mode': userAuth.userLoggedIn[0].tipo
      };
      request.headers = headers;
    } else {
      const headers = {
        'x-access-company': request.data.company
      };
      request.headers = headers;
    }
    if (request.url.split('/')[2] !== 'getNotifications') {
      store.dispatch(startAndStop(true));
    }
    return requestHandler(request);
  },
  (error) => errorHandler(error)
);

configAxios.interceptors.response.use(
  (response) => {
    store.dispatch(startAndStop(false));
    return responseHandler(response);
  },
  (error) => {
    errorHandler(error);
  }
);

export default configAxios;
