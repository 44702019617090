/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { BootstrapDialog, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { viaCep, validCep } from '../../../../@core/utils/utilsFunctions';
import { NotificationManager } from 'react-notifications';
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Autocomplete
} from '@mui/material';

import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import configAxios from '../../../../services/configAxios';
import InputMask from 'react-input-mask';

const AddEditAddress = (props) => {
  const [infosItem, setInfosItem] = useState({});
  const [typesAddress, setTypeAddress] = useState([]);

  useEffect(() => {
    getTypeAddress();
    setInfosItem({
      ...props.infosItemEdit[0],
      id_broker: props.otherInfos.id
    });
  }, [props.infosItemEdit]);

  function getTypeAddress() {
    configAxios
      .get('/address/typeAddressLabel/')
      .then((response) => {
        setTypeAddress(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as informações do tipo de endereço! Tente novamente!',
          'Atenção'
        );
      });
  }

  async function HandleInputCep(event) {
    if (event.target.value) {
      if (validCep(event.target.value)) {
        let infosCep;
        infosCep = await viaCep(event.target.value);
        if (infosCep) {
          HandleInputChange({ ...infosCep }, true);
          return;
        }
        NotificationManager.danger(
          'Tivemos dificuldades para buscar as informações do endereço, tente novamente!',
          'Atenção'
        );
      } else {
        NotificationManager.warning('Cep inválido, verifique e tente novamente!', 'Atenção');
      }
    }
  }

  function HandleInputChange(event, cep, autocomplete) {
    if (autocomplete) {
      if (event) {
        setInfosItem({ ...infosItem, desctype: event.label, id_type_address: event.id });
        return;
      }
      return;
    }
    if (cep) {
      setInfosItem({ ...infosItem, ...event });
      return;
    }
    setInfosItem({ ...infosItem, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    let route = infosItem.id ? '/address/updateAddress' : '/address/addAddress';
    let message = infosItem.id ? 'Endereço editado com sucesso!' : 'Endereço inserido com sucesso!';
    configAxios
      .post(route, { infosItem })
      .then(() => {
        props.setOpenModal(false);
        props.functionGrid();
        setInfosItem({});
        NotificationManager.success(message, 'Sucesso');
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
            props.functionGrid();
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'md'}
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
              props.functionGrid();
            }}>
            {infosItem.id ? 'Editar endereço' : 'Cadastrar endereço'}
          </BootstrapDialogTitle>
          <form onSubmit={HandleSubmit}>
            <DialogContent dividers>
              <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                {/* {infosItem.id && (
                  <Grid item xs={2} md={1}>
                    <TextField
                      name="id"
                      fullWidth
                      label="ID"
                      variant="outlined"
                      size="small"
                      required
                      value={infosItem.id || ''}
                      disabled
                    />
                  </Grid>
                )} */}
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    options={typesAddress}
                    renderOption={(props, option) => <li {...props}>{option.label}</li>}
                    isOptionEqualToValue={(option, value) =>
                      value ? option.label === value : option.label === ''
                    }
                    onChange={(event, value) => HandleInputChange(value, false, true)}
                    value={infosItem.desctype || ''}
                    defaultValue=""
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo de endereço"
                        name="desctype"
                        fullWidth
                        size="small"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} md={2}>
                  <InputMask
                    mask="99999-999"
                    onChange={(event) => HandleInputChange(event)}
                    onBlur={(event) => HandleInputCep(event)}
                    value={infosItem.cep || ''}>
                    {() => (
                      <TextField
                        name="cep"
                        fullWidth
                        label="CEP"
                        variant="outlined"
                        size="small"
                        required
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    name="rua"
                    fullWidth
                    label="Rua"
                    required
                    size="small"
                    variant="outlined"
                    value={infosItem.rua || ''}
                    onChange={(event) => HandleInputChange(event)}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    name="numero"
                    fullWidth
                    label="Número"
                    value={infosItem.numero || ''}
                    required
                    size="small"
                    variant="outlined"
                    type="number"
                    onChange={(event) => HandleInputChange(event)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    name="complemento"
                    fullWidth
                    label="Complemento"
                    size="small"
                    value={infosItem.complemento || ''}
                    variant="outlined"
                    onChange={(event) => HandleInputChange(event)}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
                <Grid item xs={12} md={4}>
                  <TextField
                    name="bairro"
                    fullWidth
                    label="Bairro"
                    required
                    size="small"
                    variant="outlined"
                    value={infosItem.bairro || ''}
                    onChange={(event) => HandleInputChange(event)}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    name="cidade"
                    fullWidth
                    label="Cidade"
                    disabled
                    variant="outlined"
                    size="small"
                    required
                    value={infosItem.cidade || ''}
                    onChange={(event) => HandleInputChange(event)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    name="estado"
                    fullWidth
                    label="Estado"
                    disabled
                    required
                    size="small"
                    variant="outlined"
                    value={infosItem.estado || ''}
                    onChange={(event) => HandleInputChange(event)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="success"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-fill" />}>
                {infosItem.id ? 'Atualizar' : 'Salvar'}
              </Button>
              <Button
                onClick={() => {
                  props.setOpenModal(false);
                  props.functionGrid();
                  setInfosItem({});
                }}
                color="error"
                startIcon={<Iconify icon="eva:close-circle-fill" />}
                variant="contained">
                Cancelar
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default AddEditAddress;
