import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { Stack, Typography, Button, Card, Grid, TextField } from '@mui/material';
import { isMobile } from 'react-device-detect';

import configAxios from '../../../../services/configAxios';
import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import MoreMenu from '../../../components/multiplesComponents/MoreMenu';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

const allowedPageSizes = [5, 10, 20, 30];

const RepClients = (props) => {
  const [infosSales, setInfosSales] = useState({});

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    configAxios
      .get('/products/getProducts')
      .then(() => {
        setInfosSales({});
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os produtos, tente novamente!',
          'Atenção'
        );
      });
  }

  function ActionsButtons(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          router: 'editProducts',
          functionGrid: getProducts,
          otherInfos: item
        }}
      />
    ));
  }

  return (
    <RenderPage title="Comissões">
      <>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Comissões de vendas
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para visualizar as comissões das vendas
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                onClick={getProducts}
                sx={{ ml: 1 }}
                startIcon={<Iconify icon="eva:refresh-outline" />}>
                Atualizar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <CustomInfosForm>
              Utilize os campos abaixo para filtrar as comissões das vendas
            </CustomInfosForm>
            <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
              <Grid item xs={12} md={3}>
                <TextField
                  name="dateinitial"
                  fullWidth
                  label="Data inicial"
                  variant="outlined"
                  size="small"
                  type="date"
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  name="dateend"
                  fullWidth
                  label="Data final"
                  variant="outlined"
                  size="small"
                  type="date"
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              {!isMobile && (
                <Grid item xs={12} md={2}>
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    startIcon={<Iconify icon="eva:funnel-outline" />}
                    // onClick={() => getSimulations()}
                    sx={{ mr: 2 }}>
                    Filtrar
                  </Button>
                </Grid>
              )}
              {isMobile && (
                <Grid item xs={12} md={2}>
                  <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mb: 1 }}>
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      startIcon={<Iconify icon="eva:funnel-outline" />}
                      // onClick={() => getSimulations()}
                      sx={{ mr: 2 }}>
                      Filtrar
                    </Button>
                  </Stack>
                </Grid>
              )}
            </Grid>
            <DataGrid
              dataSource={infosSales}
              showBorders={true}
              remoteOperations={true}
              wordWrapEnabled={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              noDataText={'Não foram encontrados registros para o período'}>
              <Column dataField="id" />
              <Column dataField="codplano" caption="Código do plano" width={150} />
              <Column dataField="descplano" caption="Descrição do plano" />
              <Column dataField="tpcontrat" caption="Tipo de contratação" />
              <Column dataField="status" caption="Status" />
              <Column
                dataField="Ações"
                width={100}
                allowSorting={false}
                cellRender={(data) => <ActionsButtons data={data} />}
              />
              <SearchPanel visible={true} width={240} placeholder="Procurar..." />
              {!isMobile && (
                <GroupPanel visible={true} emptyPanelText="Arraste as colunas aqui para agrupar" />
              )}
              <Scrolling rowRenderingMode="virtual"></Scrolling>
              <Paging defaultPageSize={10} />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              <ColumnChooser
                enabled={true}
                title={'Escolher uma coluna'}
                emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
              />
              <ColumnFixing enabled={true} />
              <HeaderFilter visible={true} allowSearch={true} />
            </DataGrid>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default RepClients;
