import { useState, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../store/login/loginSlice';

import ImageBackground from '../../assets/images/multiples/background.jpg';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import Footer from './Footer';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Link from '@mui/material/Link';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  borderRadius: '5px',

  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const fabStyle = {
  position: 'fixed',
  bottom: 16,
  right: 16
};

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const image = useMemo(() => ImageBackground, []);
  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  return (
    <>
      <Box
        sx={{
          flex: '100%',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundImage: `url(${image})`,
          backgroundSize: '100% auto;',
          backgroundPosition: 'right top',
          backgroundAttachment: 'fixed'
        }}>
        <Box component="main" sx={{ flex: 1 }}>
          <RootStyle>
            <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
            <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
            <MainStyle>
              {!isMobile && infoState.id_type_user === 1 && (
                <Link href="https://mplansistemas.tomticket.com/" target="_blank">
                  <Fab color="primary" aria-label="support" sx={fabStyle}>
                    <SupportAgentIcon />
                  </Fab>
                </Link>
              )}
              <Outlet />
            </MainStyle>
          </RootStyle>
        </Box>
        <Box component="footer" sx={!isMobile ? { px: 40, py: 1 } : { py: 1 }}>
          <Footer />
        </Box>
      </Box>
    </>
  );
}
