/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BootstrapDialog, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../../store/login/loginSlice';
import { startAndStop } from '../../../../store/loading/loadingSlice';
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Box,
  Tab,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete
} from '@mui/material';

import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import axios from 'axios';
import configAxios from '../../../../services/configAxios';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Iconify from '../../../../@core/theme/Iconify';
const allowedPageSizes = [5, 10, 20, 30];

const integrationClients = (props) => {
  const dispatch = useDispatch();
  const [infosHistoryIntegration, setHistoryIntegration] = useState([]);
  const [defaultErrors, setDefaultErros] = useState([]);
  const [resultIntegration, setResultIntegration] = useState([]);
  const infoStateTemp = useSelector(selectLogin);
  const [value, setValue] = useState('1');
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });
  const [infosAdm, setInfosAdm] = useState([]);
  const [labelCodEmp, setLabelCodEmp] = useState([]);
  const [labelGrupoEmp, setLabelGrupoEmp] = useState([]);
  const [labelCodBank, setLabelCodBank] = useState([]);

  useEffect(() => {
    setHistoryIntegration(props.infosHistoryIntegration);
    setResultIntegration(
      props.infosHistoryIntegration.filter((element) => element.status === 'Integrada')
    );
  }, [props.infosHistoryIntegration]);

  useEffect(() => {
    if (infoState.id_type_user === 1) {
      if (props.infosClients.tpcontrat_client === '1-INDIVIDUAL/FAMILIAR') {
        getCodEmp();
      }
      if (props.infosClients.tpcontrat_client === '2-COLETIVO EMPRESARIAL') {
        getGrupoEmp();
        getCodBank();
      }
    }
  }, []);

  function ChangedDate(date) {
    return date.data.data.integration_at.replaceAll('-', ':');
  }

  function NameUser(infos) {
    if (infos.data.data.nome) {
      return infos.data.data.nome;
    }
    return '-';
  }

  function RenderCodGer(infos) {
    if (infos.data.data.codger) {
      return infos.data.data.codger;
    }
    return '-';
  }

  function HandleTabsChange(event, newValue) {
    setValue(newValue);
  }

  function HandleInputChange(event, params = {}) {
    if (params.codemp) {
      if (event) {
        setInfosAdm({ ...infosAdm, labelEmp: event.label, codemp: event.codemp });
      }
      return;
    }

    if (params.codgrupoemp) {
      if (event) {
        setInfosAdm({ ...infosAdm, labelGrupo: event.label, codgrupo: event.codgrupoemp });
      }
      return;
    }

    if (params.codbanco) {
      if (event) {
        setInfosAdm({ ...infosAdm, labelBank: event.label, codbanco: event.codbanco });
      }
      return;
    }

    setInfosAdm({ ...infosAdm, [event.target.name]: event.target.value });
    return;
  }

  async function IntegrationClient(event) {
    event.preventDefault();
    const urlIntegration = infoState.rotapostback;
    const tempDefaultErrors = [];
    const plansIntegrations = [];

    if (props.infosClients.tpcontrat === '3-COLETIVO POR ADESAO') {
      if (!props.infosClients.codextassociation) {
        tempDefaultErrors.push({ errors: 'Código externo da associação' });
      }
    }

    if (props.infosClients.tpcontrat !== '2-COLETIVO EMPRESARIAL') {
      if (props.infosClients.titbenef === 'S') {
        if (!props.infosClients.codcarext) {
          tempDefaultErrors.push({
            errors: `Código externo da carência - ${props.infosClients.nome} `
          });
        }
      }
    }
    props.infosDeps.map((element) => {
      if (!element.codcarext) {
        tempDefaultErrors.push({ errors: `Código externo da carência - ${element.nome} ` });
      }
    });

    if (!props.infosClients.codextplano) {
      tempDefaultErrors.push({ errors: 'Código externo do plano' });
    }
    if (!props.infosClients.codexttable) {
      tempDefaultErrors.push({ errors: 'Código externo da tabela' });
    }
    if (!props.infosClients.codextgerente && infoState.controlagerente === 'Sim') {
      tempDefaultErrors.push({ errors: 'Código externo do gerente' });
    }
    if (!props.infosClients.codextbroker) {
      tempDefaultErrors.push({ errors: 'Código externo da corretora' });
    }
    if (!props.infosClients.codextvend) {
      tempDefaultErrors.push({ errors: 'Código externo do vendedor' });
    }
    setDefaultErros(tempDefaultErrors);

    if (!tempDefaultErrors.length) {
      props.infosDeps.map((item) => {
        plansIntegrations.push({
          codplano: item.codextplano,
          codtab: item.codexttable
        });
      });
      const postObject = {
        infosClient: { ...props.infosClients },
        infosAddress: props.infosAddress,
        infosDeps: props.infosDeps,
        infosAdm: infosAdm,
        multiplesPlans: plansIntegrations
      };
      dispatch(startAndStop(true));
      try {
        const codger = await axios.post(`${urlIntegration}/sales/integration`, postObject, {
          headers: {
            authorization: `Bearer ${infoState.tokenpostback}`
          }
        });
        dispatch(startAndStop(false));
        const postHistory = {
          codger: codger.data,
          id_client: props.infosClients.id,
          user_id: infoState.id,
          status: 'Integrada'
        };
        await configAxios.post('/integration/integrationAddHistory', postHistory);
        NotificationManager.success('Venda integrada com sucesso!', 'Sucesso');
        props.getHistoryIntegration();
        // props.setOpenModal(false);
      } catch {
        dispatch(startAndStop(false));
        const postHistory = {
          id_client: props.infosClients.id,
          user_id: infoState.id,
          status: 'Não integrada'
        };
        await configAxios.post('/integration/integrationAddHistory', postHistory);
        props.getHistoryIntegration();
        NotificationManager.error(
          'Tivemos dificuldades para atender a requisição no momento, por favor, tente novamente!',
          'Atenção'
        );
      }
      return;
    }
    NotificationManager.warning(
      'É necessário adequar todos os campos para integrar, por favor, tente novamente!',
      'Atenção'
    );
  }

  async function getCodEmp() {
    const urlIntegration = infoState.rotapostback;
    axios
      .get(`${urlIntegration}/company/getCodEmp`, {
        headers: {
          authorization: `Bearer ${infoState.tokenpostback}`
        }
      })
      .then((response) => {
        setLabelCodEmp(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as empresas para integração, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  async function getGrupoEmp() {
    const urlIntegration = infoState.rotapostback;
    axios
      .get(`${urlIntegration}/company/getGrupoEmp`, {
        headers: {
          authorization: `Bearer ${infoState.tokenpostback}`
        }
      })
      .then((response) => {
        setLabelGrupoEmp(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os grupos para integração, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  async function getCodBank() {
    const urlIntegration = infoState.rotapostback;
    axios
      .get(`${urlIntegration}/company/getCodBank`, {
        headers: {
          authorization: `Bearer ${infoState.tokenpostback}`
        }
      })
      .then((response) => {
        setLabelCodBank(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os grupos para integração, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'sm'}
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
            }}>
            Integrações de vendas
          </BootstrapDialogTitle>
          <Box sx={{ width: '100%' }}>
            <form onSubmit={IntegrationClient}>
              <DialogContent dividers>
                <TabContext value={value}>
                  <TabList onChange={HandleTabsChange} aria-label="SendEmailContract">
                    <Tab label="Histórico" value="1" />
                    {props.infosClients.tpcontrat_client !== '3-COLETIVO POR ADESAO' && (
                      <Tab label="Administrativo" value="2" />
                    )}
                    {defaultErrors.length && (
                      <Tab label="Campos para adequar" value="3" sx={{ textTransform: 'none' }} />
                    )}
                  </TabList>
                  <TabPanel value="1" variant="fullWidth">
                    {/* <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Historico das integrações
                      </Typography>
                    </Grid> */}
                    <Grid item xs={12} sx={{ mt: -1 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        Acompanhe abaixo o histórico de integrações
                      </Typography>
                    </Grid>
                    <DataGrid
                      dataSource={infosHistoryIntegration}
                      showBorders={true}
                      remoteOperations={true}
                      wordWrapEnabled={true}
                      allowColumnResizing={true}
                      columnAutoWidth={true}
                      noDataText={'Ainda não foram realizadas integrações'}>
                      <Column
                        dataField="nome"
                        caption="Usuário"
                        width={170}
                        cellRender={(data) => <NameUser data={data} />}
                      />
                      <Column dataField="status" caption="Status" />
                      <Column
                        dataField="codger"
                        caption="Código"
                        width={85}
                        cellRender={(data) => <RenderCodGer data={data} />}
                      />
                      <Column
                        dataField="created_at"
                        caption="Data e hora"
                        cellRender={(data) => <ChangedDate data={data} />}
                      />
                      <SearchPanel visible={true} width={220} placeholder="Procurar..." />
                      <GroupPanel
                        visible={true}
                        emptyPanelText="Arraste as colunas aqui para agrupar"
                      />
                      <Scrolling rowRenderingMode="virtual"></Scrolling>
                      <Paging defaultPageSize={5} />
                      <Pager
                        visible={true}
                        allowedPageSizes={allowedPageSizes}
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                      />
                      <ColumnChooser
                        enabled={true}
                        title={'Escolher uma coluna'}
                        emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                      />
                      <ColumnFixing enabled={true} />
                      <HeaderFilter visible={true} allowSearch={true} />
                    </DataGrid>
                  </TabPanel>
                  <TabPanel value="2" variant="fullWidth">
                    {/* <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Informações administrativas
                      </Typography>
                    </Grid> */}
                    <Grid item xs={12} sx={{ mt: -1 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        Insira as informações administrativas para integrar
                      </Typography>
                    </Grid>
                    <CustomInfosForm sx={{ fontSize: '12px' }}>
                      Campos obrigatórios possuem asterisco (*)
                    </CustomInfosForm>
                    {props.infosClients.tpcontrat_client === '1-INDIVIDUAL/FAMILIAR' && (
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} md={12}>
                          <Autocomplete
                            options={labelCodEmp}
                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                            isOptionEqualToValue={(option, value) =>
                              value ? option.label === value : option.label === ''
                            }
                            onChange={(event, value) => HandleInputChange(value, { codemp: true })}
                            value={infosAdm.labelEmp || ''}
                            defaultValue=""
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Código da empresa"
                                name="codemp"
                                fullWidth
                                size="small"
                                variant="outlined"
                                required
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {props.infosClients.tpcontrat_client !== '1-INDIVIDUAL/FAMILIAR' && (
                      <>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} md={12}>
                            <Autocomplete
                              options={labelGrupoEmp}
                              renderOption={(props, option) => <li {...props}>{option.label}</li>}
                              isOptionEqualToValue={(option, value) =>
                                value ? option.label === value : option.label === ''
                              }
                              onChange={(event, value) =>
                                HandleInputChange(value, { codgrupoemp: true })
                              }
                              value={infosAdm.labelGrupo || ''}
                              defaultValue=""
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Código do grupo"
                                  name="codgrupoemp"
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                  required
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} md={12}>
                            <Autocomplete
                              options={labelCodBank}
                              renderOption={(props, option) => <li {...props}>{option.label}</li>}
                              isOptionEqualToValue={(option, value) =>
                                value ? option.label === value : option.label === ''
                              }
                              onChange={(event, value) =>
                                HandleInputChange(value, { codbanco: true })
                              }
                              value={infosAdm.labelBank || ''}
                              defaultValue=""
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Código do banco"
                                  name="codbanco"
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                  required
                                />
                              )}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                          <Grid item xs={12} md={4}>
                            <InputLabel id="label" sx={{ fontSize: '13px' }}>
                              Cobrança de copart. *
                            </InputLabel>
                            <Select
                              name="pagcoop"
                              fullWidth
                              size="small"
                              required
                              value={infosAdm.pagcoop || ''}
                              onChange={(event) => HandleInputChange(event)}>
                              <MenuItem value={1}>1-BOLETO</MenuItem>
                              <MenuItem value={2}>2-EMISSÃO</MenuItem>
                              <MenuItem value={3}>3-FOLHA DE PAGAMENTO</MenuItem>
                              <MenuItem value={4}>4-PERGUNTAR</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <InputLabel id="label" sx={{ fontSize: '13px' }}>
                              Tipo de pagamento *
                            </InputLabel>
                            <Select
                              name="tppagto"
                              fullWidth
                              size="small"
                              required
                              value={infosAdm.tppagto || ''}
                              onChange={(event) => HandleInputChange(event)}>
                              <MenuItem value={'1-TOTAL'}>1-TOTAL</MenuItem>
                              <MenuItem value={'2-INDIVIDUAL'}>2-INDIVIDUAL</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={12} md={4} sx={{ mt: 2.2 }}>
                            <TextField
                              name="diapagto"
                              fullWidth
                              label="Dia do pgto"
                              size="small"
                              required
                              value={infosAdm.diapagto || ''}
                              variant="outlined"
                              onChange={(event) => HandleInputChange(event)}
                              inputProps={{ maxLength: 2 }}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </TabPanel>
                  <TabPanel value="3" variant="fullWidth">
                    <Grid item xs={12} sx={{ mt: -1 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        Acompanhe abaixo os campos para adequar
                      </Typography>
                    </Grid>
                    <DataGrid
                      dataSource={defaultErrors}
                      showBorders={true}
                      remoteOperations={true}
                      wordWrapEnabled={true}
                      allowColumnResizing={true}
                      columnAutoWidth={true}>
                      <Column dataField="errors" caption="Campos para adequar" />
                      <Scrolling rowRenderingMode="virtual"></Scrolling>
                      <Paging defaultPageSize={5} />
                      <Pager
                        visible={true}
                        allowedPageSizes={allowedPageSizes}
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                      />
                      <ColumnFixing enabled={true} />
                    </DataGrid>
                  </TabPanel>
                </TabContext>
              </DialogContent>
              <DialogActions>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="success"
                  disabled={
                    resultIntegration.length ||
                    (props.infosClients.tpcontrat_client != '3-COLETIVO POR ADESAO' &&
                      value !== '2')
                      ? true
                      : false
                  }
                  startIcon={<Iconify icon="eva:cloud-upload-fill" />}>
                  Integrar
                </Button>
                <Button
                  onClick={() => {
                    props.setOpenModal(false);
                    setDefaultErros([]);
                    setValue('1');
                  }}
                  color="error"
                  variant="contained"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Fechar
                </Button>
              </DialogActions>
            </form>
          </Box>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default integrationClients;
